import React from 'react'
import Header from '../Components/Header'
import AllClasses from '../Components/AllClasses'

const Classes = () => {
  return (
    <div className='main-wrapper'>
      <Header/>
      <AllClasses/>
    </div>
  )
}

export default Classes
