import React, { useState } from 'react'
import Firebase from '../Firebase'

const AddSchool = () => {
    const[Obj,SetObj]=useState({
        SchoolName:"",
        SchoolPhone:"",
        SchoolEmail:"",
        SchoolTimings:"",
        PeriodsDay:"None",
        PeriodsDuration:"None",
        Address:"",
        City:"",
        State:"",
        ZipCode:"",
        Country:""
    })
    var d=new Date()
  var date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
    function set(event)
    {
        SetObj({...Obj,[event.target.name]:event.target.value,"Date":date})
    }
    function Submit(e)
    {
        e.preventDefault()
        if(Obj.SchoolName!="" && Obj.SchoolPhone!="" && Obj.SchoolEmail!="" && Obj.SchoolTimings!="" && Obj.PeriodsDay!="None" && Obj.PeriodsDuration!="None" && Obj.Address!="" && Obj.City!="" && Obj.State!="" && Obj.ZipCode!="" && Obj.Country!="")
        {
            Firebase.child("SchoolTimetable/SchoolDetails").update(Obj,err=>{
                if(err)
                {
                    alert("Error Occured")
                }
                else
                {
                    alert("School Details Updated")
                }
            })
        }
        else
        {
            alert("Field is Empty")
        }
    }
  return (
    <div className="page-wrapper">
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">School Details</h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <h5 className="form-title"><span>Basic Details</span></h5>
                  </div>
                  
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>School Name <span className="login-danger">*</span></label>
                      <input type="text" onChange={set} name='SchoolName' className="form-control" placeholder="Enter Name" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>School Phone Number<span className="login-danger">*</span></label>
                      <input type="number" onChange={set} name='SchoolPhone' className="form-control" placeholder="Enter Phone Number" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>School Email ID<span className="login-danger">*</span></label>
                      <input type="email" onChange={set} name='SchoolEmail' className="form-control" placeholder="Enter School Email ID" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>School Timings<span className="login-danger">*</span></label>
                      <input type="time" onChange={set} name='SchoolTimings' className="form-control" placeholder="Enter School Timings" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>Periods/Day<span className="login-danger">*</span></label>
                      <select onChange={set} name='PeriodsDay' className="form-control select">
                        <option value="None">None</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>Periods/Duration <span className="login-danger">*</span></label>
                      <select onChange={set} name='PeriodsDuration' className="form-control select">
                        <option value="None">None</option>
                        <option value="30">30 min.</option>
                        <option value="35">35 min.</option>
                        <option value="40">40 min.</option>
                        <option value="45">45 min.</option>
                        <option value="50">50 min.</option>
                        <option value="60">60 min.</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <h5 className="form-title"><span>Address</span></h5>
                  </div>
                  <div className="col-12">
                    <div className="form-group local-forms">
                      <label>Address <span className="login-danger">*</span></label>
                      <input type="text" onChange={set} name='Address' className="form-control" placeholder="Enter address" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>City <span className="login-danger">*</span></label>
                      <input type="text" onChange={set} name='City' className="form-control" placeholder="Enter City" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>State <span className="login-danger">*</span></label>
                      <input type="text" onChange={set} name='State' className="form-control" placeholder="Enter State" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>Zip Code <span className="login-danger">*</span></label>
                      <input type="text" onChange={set} name='ZipCode' className="form-control" placeholder="Enter Zip" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>Country <span className="login-danger">*</span></label>
                      <input type="text" onChange={set} name='Country' className="form-control" placeholder="Enter Country" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="student-submit">
                      <button onClick={Submit} className="btn btn-primary">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AddSchool
