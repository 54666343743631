import React from 'react'
import Header from '../Components/Header'
import Student_dashcomp from '../Components/Student_dashcomp'

const Student_dashboard = () => {
  return (
    <div className="main-wrapper">
      <Header/>
      <Student_dashcomp/>
    </div>
  )
}

export default Student_dashboard
