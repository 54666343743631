import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
const firebaseConfig = {
  apiKey: "AIzaSyCkLnD8xXQS1cjjvfj0M2pKfqfCKh416BQ",
  authDomain: "student-project-cf5e7.firebaseapp.com",
  databaseURL: "https://student-project-cf5e7-default-rtdb.firebaseio.com",
  projectId: "student-project-cf5e7",
  storageBucket: "student-project-cf5e7.appspot.com",
  messagingSenderId: "134221481721",
  appId: "1:134221481721:web:ddf690664abfe440764428"
  };


const Firebase = firebase.initializeApp(firebaseConfig)
export default  Firebase.database().ref();