import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Firebase from '../Firebase'

const Login = () => {
  const[Obj,SetObj]= useState({
    Username:"",
    Password:""
  })
  const[Condition,SetCondition]=useState(false)
  var navigate=useNavigate()
  useEffect(function()
  {
    Firebase.child("SchoolTimetable/SchoolDetails").on("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetCondition(false)
      }
      else
      {
        SetCondition(true)
      }
    })
  },[])
 function set(e)
 {
  SetObj({...Obj,[e.target.name]:e.target.value})
 }
 function Submit(e)
 {
  e.preventDefault()
  if(Obj.Password!="" && Obj.Username!="")
  {
    if(Obj.Username=="Admin" && Obj.Password=="Admin123")
    {
      if(Condition==true)
      {
        navigate("/Dashboard")
      }
      else
      {
        navigate("/Help")
      }

    }
    else
    {
      Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
      {
        if(snap.val()==null)
        {
          alert("Some Error Occured Try again later")
        }
        else
        {
         var result= Object.keys(snap.val()).filter(function(key)
          {
           return Obj.Username==snap.val()[key].Username && Obj.Password==snap.val()[key].Password
          })
          if(result=="")
          {
            alert("Wrong Username and Password")
          }
          else
          {
            navigate(`/TeacherDashboard/${result[0]}`)
          }
        }
      })
    }
  } 
  else
  {
    alert("Field is Empty")
  }
 }
  return (
 <div className="main-wrapper login-body">
  <div className="login-wrapper">
    <div className="container">
      <div className="loginbox">
        <div className="login-left">
          <img className="img-fluid" src="assets/img/login.png" alt="Logo" />
        </div>
        <div className="login-right">
          <div className="login-right-wrap">
            <h1>Welcome to Academic Organizer</h1>
            <p className="account-subtitle">Need an account? <Link to="Signup">Sign Up</Link></p>
            <h2>Sign in</h2>
            <form>
              <div className="form-group">
                <label>Username <span className="login-danger">*</span></label>
                <input onChange={set} name='Username' className="form-control" type="text" />
                <span className="profile-views"><i className="fas fa-user-circle" /></span>
              </div>
              <div className="form-group">
                <label>Password <span className="login-danger">*</span></label>
                <input onChange={set} name='Password' className="form-control pass-input" type="password" />
                <span className="profile-views feather-eye toggle-password" />
              </div>
              {/* <div className="forgotpass">
                <div className="remember-me">
                  <label className="custom_check mr-2 mb-0 d-inline-flex remember-me"> Remember me
                    <input type="checkbox" name="radio" />
                    <span className="checkmark" />
                  </label>
                </div>
                <a href="forgot-password.html">Forgot Password?</a>
              </div> */}
              <div className="form-group">
                <button className="btn btn-primary btn-block" onClick={Submit}>Login</button>
              </div>
            </form>
            {/* <div className="login-or">
              <span className="or-line" />
              <span className="span-or">or</span>
            </div>
            <div className="social-login">
              <a href="#"><i className="fab fa-google-plus-g" /></a>
              <a href="#"><i className="fab fa-facebook-f" /></a>
              <a href="#"><i className="fab fa-twitter" /></a>
              <a href="#"><i className="fab fa-linkedin-in" /></a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Login
