import React from 'react'
import Header from '../Components/Header'
import Teacher_dashcomp from '../Components/Teacher_dashcomp'

const Teacher_dashboard = () => {
  return (
    <div>
      <Teacher_dashcomp/>
    </div>
  )
}

export default Teacher_dashboard
