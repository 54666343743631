import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Firebase from '../Firebase'
import JsPDF from 'jspdf'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
const TodayComponent = () => {
  const[Obj,SetObj]= useState({})
   const[Obj1,SetObj1]= useState({})
   var navigate=useNavigate()
   var d=new Date()
   var date=d.getDate()
   var month=d.getMonth()+1
   var year=d.getFullYear()
   useEffect(function()
   {
    Firebase.child(`SchoolTimetable/TeachersList`).on("value",function(snap)
    {
        if(snap.val()==null)
        {
            SetObj({})
        }
        else
        {
            SetObj(snap.val())
            SetObj1(snap.val())
        }
    })
   },[])
   function search(event)
   {
        if(event.target.value=="")
        {
            SetObj(Obj1)
        }
        else
        {   var obj={}
            Object.keys(Obj1).map(function(key)
            {
                if(Obj1[key].Name.match(event.target.value[0].toUpperCase()+event.target.value.slice(1)) || Obj1[key].Phone.match(event.target.value) || Obj1[key].Email.match(event.target.value[0].toUpperCase()+event.target.value.slice(1)) || Obj1[key].Name.match(event.target.value) || Obj1[key].Email.match(event.target.value))
                {
                    obj[key]=Obj1[key]
                }
            })
            SetObj(obj)
        }    
   }
   function generatePDF()
 {
 var arr= document.getElementById('table')

  const report = new JsPDF('landscape','px',[700,1300]);
  report.html(arr).then(() => {
     report.save('Attendance.pdf');
})
 }
  return (
    <div>
    <div className="page-wrapper">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Attendance</h3>
        </div>
      </div>
    </div>
    <div className="student-group-form">
      <div className="row">
        <div className="col-lg-11 col-md-11">
          <div className="form-group">
            <input type="text" className="form-control" onChange={search} placeholder="Search by Name, Phone Number, Qualification" />
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-table">
          <div className="card-body">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Today Attendance</h3>
                </div>
                <div className="col-auto text-end float-end ms-auto download-grp">
                <ReactHTMLTableToExcel
                    table="table"
                    filename="Attendance"
                    sheet="Attendance"
                    className="btn btn-outline-primary me-2"
                    buttonText="Download Excel">
                    </ReactHTMLTableToExcel>
                  <a onClick={generatePDF} className="btn btn-outline-primary me-2"><i className="fas fa-download" /> Download</a>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table id='table' className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                <thead className="student-thread">
                  <tr>
                    <th className="text-center">S.No.</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Phone</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">Main Subject</th>
                    <th className="text-center">Other Subject</th>
                    <th className="text-center">Date of Joining</th>
                    <th className="text-center">{date}-{month}-{year}</th>
                  </tr>
                </thead>
                <tbody>
                {
                            Obj?Object.keys(Obj).map(function(key,index)
                            {
                                if(Obj[key].Attendance!=null)
                                {
                                   if(Obj[key].Attendance[year]!=null)
                                   {
                                    if(Obj[key].Attendance[year][month]!=null)
                                    {
                                        if(Obj[key].Attendance[year][month][date]==null)
                                        {
                                            return(
                                                <tr className='text-center' key={key}>
                                                <td>{index+1}</td>
                                              <td>{Obj[key].Name}</td>
                                              <td>{Obj[key].Phone}</td>
                                              <td>{Obj[key].Email}</td>
                                              <td>{Obj[key].Specialist}</td>
                                              <td>{Obj[key].OtherSubject}</td>
                                              <td>{Obj[key].Date}</td>
                                              <td>Absent</td>
                                              </tr>
                                            )
                                        }
                                        else
                                        {
                                        return(
                                          <tr className='text-center' key={key}>
                                              <td>{index+1}</td>
                                              <td>{Obj[key].Name}</td>
                                              <td>{Obj[key].Phone}</td>
                                              <td>{Obj[key].Email}</td>
                                              <td>{Obj[key].Specialist}</td>
                                              <td>{Obj[key].OtherSubject}</td>
                                              <td>{Obj[key].Date}</td>
                                           <td>{Obj[key].Attendance[year][month][date].Status}</td> 
                                          </tr>
                                        )
                                        }
                                    }
                                    else
                                    {
                                        return(
                                            <tr className='text-center' key={key}>
                                            <td>{index+1}</td>
                                          <td>{Obj[key].Name}</td>
                                          <td>{Obj[key].Phone}</td>
                                          <td>{Obj[key].Email}</td>
                                              <td>{Obj[key].Specialist}</td>
                                              <td>{Obj[key].OtherSubject}</td>
                                              <td>{Obj[key].Date}</td>
                                          <td>Absent</td>
                                          </tr>)  
                                    }
                                   }
                                   else
                                   {
                                    return(
                                        <tr className='text-center' key={key}>
                                        <td>{index+1}</td>
                                      <td>{Obj[key].Name}</td>
                                      <td>{Obj[key].Phone}</td>
                                      <td>{Obj[key].Email}</td>
                                              <td>{Obj[key].Specialist}</td>
                                              <td>{Obj[key].OtherSubject}</td>
                                              <td>{Obj[key].Date}</td>
                                      <td>Absent</td>
                                      </tr>)
                                   }
                                }
                                else
                                {
                                    return(
                                        <tr className='text-center' key={key}>
                                        <td>{index+1}</td>
                                      <td>{Obj[key].Name}</td>
                                      <td>{Obj[key].Phone}</td>
                                      <td>{Obj[key].Email}</td>
                                              <td>{Obj[key].Specialist}</td>
                                              <td>{Obj[key].OtherSubject}</td>
                                              <td>{Obj[key].Date}</td>
                                      <td>Absent</td>
                                      </tr>
                                    )
                                }
                            }):""
                        }
                  {/* <tr>
                    <td>PRE2309</td>
                    <td>
                      <h2 className="table-avatar">
                        <a className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-01.jpg" alt="User Image" /></a>
                        <a>Aaliyah</a>
                      </h2>
                    </td>
                    <td>10</td>
                    <td>English</td>
                    <td>10:00 AM</td>
                    <td>01:00 PM</td>
                    <td>23 Apr 2020</td>
                    <td className="text-end">
                      <div className="actions">
                        <a href="javascript:;" className="btn btn-sm bg-success-light me-2">
                          <i className="feather-eye" />
                        </a>
                        <a href="edit-time-table.html" className="btn btn-sm bg-danger-light">
                          <i className="feather-edit" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>PRE2209</td>
                    <td>
                      <h2 className="table-avatar">
                        <a className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-02.jpg" alt="User Image" /></a>
                        <a>Malynne</a>
                      </h2>
                    </td>
                    <td>1</td>
                    <td>Botony</td>
                    <td>10:00 AM</td>
                    <td>01:00 PM</td>
                    <td>23 Apr 2020</td>
                    <td className="text-end">
                      <div className="actions">
                        <a href="javascript:;" className="btn btn-sm bg-success-light me-2">
                          <i className="feather-eye" />
                        </a>
                        <a href="edit-time-table.html" className="btn btn-sm bg-danger-light">
                          <i className="feather-edit" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>PRE2213</td>
                    <td>
                      <h2 className="table-avatar">
                        <a className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-03.jpg" alt="User Image" /></a>
                        <a>Levell&nbsp;Scott</a>
                      </h2>
                    </td>
                    <td>9</td>
                    <td>Biology</td>
                    <td>01:00 PM</td>
                    <td>04:00 PM</td>
                    <td>26 Nov 2020</td>
                    <td className="text-end">
                      <div className="actions">
                        <a href="javascript:;" className="btn btn-sm bg-success-light me-2">
                          <i className="feather-eye" />
                        </a>
                        <a href="edit-time-table.html" className="btn btn-sm bg-danger-light">
                          <i className="feather-edit" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>PRE2143</td>
                    <td>
                      <h2 className="table-avatar">
                        <a className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-04.jpg" alt="User Image" /></a>
                        <a>Minnie</a>
                      </h2>
                    </td>
                    <td>8</td>
                    <td>Science</td>
                    <td>01:00 PM</td>
                    <td>04:00 PM</td>
                    <td>18 Sep 2020</td>
                    <td className="text-end">
                      <div className="actions">
                        <a href="javascript:;" className="btn btn-sm bg-success-light me-2">
                          <i className="feather-eye" />
                        </a>
                        <a href="edit-time-table.html" className="btn btn-sm bg-danger-light">
                          <i className="feather-edit" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>PRE2009</td>
                    <td>
                      <h2 className="table-avatar">
                        <a className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-05.jpg" alt="User Image" /></a>
                        <a>Lois&nbsp;A</a>
                      </h2>
                    </td>
                    <td>7</td>
                    <td>History</td>
                    <td>01:00 PM</td>
                    <td>04:00 PM</td>
                    <td>23 Jul 2020</td>
                    <td className="text-end">
                      <div className="actions">
                        <a href="javascript:;" className="btn btn-sm bg-success-light me-2">
                          <i className="feather-eye" />
                        </a>
                        <a href="edit-time-table.html" className="btn btn-sm bg-danger-light">
                          <i className="feather-edit" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>PRE2431</td>
                    <td>
                      <h2 className="table-avatar">
                        <a className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-06.jpg" alt="User Image" /></a>
                        <a>Calvin</a>
                      </h2>
                    </td>
                    <td>2</td>
                    <td>Biology</td>
                    <td>10:00 AM</td>
                    <td>01:00 PM</td>
                    <td>15 Oct 2020</td>
                    <td className="text-end">
                      <div className="actions">
                        <a href="javascript:;" className="btn btn-sm bg-success-light me-2">
                          <i className="feather-eye" />
                        </a>
                        <a href="edit-time-table.html" className="btn btn-sm bg-danger-light">
                          <i className="feather-edit" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>PRE1534</td>
                    <td>
                      <h2 className="table-avatar">
                        <a className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-07.jpg" alt="User Image" /></a>
                        <a>Vincent</a>
                      </h2>
                    </td>
                    <td>6</td>
                    <td>Botony</td>
                    <td>10:00 AM</td>
                    <td>01:00 PM</td>
                    <td>02 Jun 2020</td>
                    <td className="text-end">
                      <div className="actions">
                        <a href="javascript:;" className="btn btn-sm bg-success-light me-2">
                          <i className="feather-eye" />
                        </a>
                        <a href="edit-time-table.html" className="btn btn-sm bg-danger-light">
                          <i className="feather-edit" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>PRE2153</td>
                    <td>
                      <h2 className="table-avatar">
                        <a className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-08.jpg" alt="User Image" /></a>
                        <a>Kozma&nbsp;&nbsp;Tatari</a>
                      </h2>
                    </td>
                    <td>12</td>
                    <td>Mathematics</td>
                    <td>10:00 AM</td>
                    <td>01:00 PM</td>
                    <td>23 Apr 2020</td>
                    <td className="text-end">
                      <div className="actions">
                        <a href="javascript:;" className="btn btn-sm bg-success-light me-2">
                          <i className="feather-eye" />
                        </a>
                        <a href="edit-time-table.html" className="btn btn-sm bg-danger-light">
                          <i className="feather-edit" />
                        </a>
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <p>Copyright © 2024 Academic Organizer.</p>
  </footer>
</div>

    </div>
  )
}

export default TodayComponent
