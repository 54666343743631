import React from 'react'
import Header from '../Components/Header'
import UploadCertificate from '../Components/UploadCertificate'

const Certificate = () => {
  return (
    <div className='main-wrapper'>
      <Header/>
      <UploadCertificate/>
    </div>
  )
}

export default Certificate
