import React from 'react'

const DaysApi = () => {
var d= new Date()
const month= d.getMonth()+1
const year=d.getFullYear()
    if(month==2)
    {
        if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
            return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29]
        }else {
            return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]
        }
    }
    else
    {
        if(month==1 || month==3 || month==5 || month==7 || month==8 || month==10 || month==12)
        {
            return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
        }
        else
        {
            return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
        }
    }

  
}

export default DaysApi
