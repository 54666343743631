import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Firebase from '../Firebase'
import jsPDF from 'jspdf'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
const ShowTimetablecomponent = () => {
    const[Obj,SetObj]=useState({})
    const[Obj1,SetObj1]=useState({})
    const[Periods,SetPeriods]=useState("")
    var {key}=useParams()
    useEffect(function()
    {
        Firebase.child(`SchoolTimetable/Classes/${key}/Timetable`).on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetObj({})
            }
            else
            {
                SetObj(snap.val())
            }
        })
        Firebase.child(`SchoolTimetable`).on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetObj1({})
            }
            else
            {
                SetObj1(snap.val())
            }
        })
    },[])
    useEffect(function()
    {
        var array=[]
        if(Object.keys(Obj1).length==0)
        {
            
        }
        else
        {
            var time=Obj1.SchoolDetails.SchoolTimings.split(":")
            var hour=parseInt(time[0])
            var minute=parseInt(time[1])
            if(parseInt(Obj1.SchoolDetails.PeriodsDay)%2!=0)
            {
                var lunch=(parseInt(Obj1.SchoolDetails.PeriodsDay/2)+2)
            }
            else
            {
                var lunch=(Obj1.SchoolDetails.PeriodsDay/2)+1
            }
            for(var i=1;i<=parseInt(Obj1.SchoolDetails.PeriodsDay);i++)
            {
                var objects={}
                if(lunch==i)
                {
                    objects.starttime=`${hour}:${minute}`
                    minute=parseInt(minute)+20
                    Obj1.SchoolDetails.PeriodsDay=parseInt(Obj1.SchoolDetails.PeriodsDay)+1
                    if(minute>=60)
                    {
                        minute=minute-60
                        if(minute==0)
                        {
                            minute="00"
                        }
                        hour=hour+1
                    }
                    objects.endtime=`${hour}:${minute}`
                    objects.period="Lunch"
                }
               else
               {
                if(lunch<i)
                {
                objects.starttime=`${hour}:${minute}`
                minute=parseInt(minute)+parseInt(Obj1.SchoolDetails.PeriodsDuration)
                if(minute>=60)
                {
                    minute=minute-60
                    if(minute==0)
                    {
                        minute="00"
                    }
                    hour=hour+1
                }
                objects.endtime=`${hour}:${minute}`
                objects.period=i-1
                }
                else
                {
                objects.starttime=`${hour}:${minute}`
                minute=parseInt(minute)+parseInt(Obj1.SchoolDetails.PeriodsDuration)
                if(minute>=60)
                {
                    minute=minute-60
                    if(minute==0)
                    {
                        minute="00"
                    }
                    hour=hour+1
                }
                objects.endtime=`${hour}:${minute}`
                objects.period=i  
                }  
               }
                array.push(objects)
            }  
        }
        SetPeriods(array)
    },[Obj1])
    function generatePDF()
 {
 var arr= document.getElementById('table')

  const report = new jsPDF('landscape','px',[700,2200]);
  report.html(arr).then(() => {
     report.save('Timetable.pdf');
})
 }
  return (
    <div className="page-wrapper">
    <div className="content container-fluid">
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col">
                    <h3 className="page-title">Time Table</h3>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <div className="card card-table">
                    <div className="card-body">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h3 className="page-title">Time Table</h3>
                                </div>
                                <div className="col-auto text-end float-end ms-auto download-grp">
                                <ReactHTMLTableToExcel
                                                        table="table"
                                                        filename="Timetable"
                                                        sheet="Timetable"
                                                        className="btn btn-outline-primary me-2"
                                                        buttonText="Download Excel">
                                                    </ReactHTMLTableToExcel>
                                    <a onClick={generatePDF} className="btn btn-outline-primary me-2"><i className="fas fa-download" /> Download</a>
                                    <Link to="/AddClasses" className="btn btn-primary"><i className="fas fa-plus" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table id='table' className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                                <thead className="student-thread">
                                    <tr className='text-center'>
                                        <th>Days/Periods</th>
                                        <>
                                            {
                                                Periods ? Periods.map(function (obj, index) {
                                                    return (
                                                        <th key={index}>{obj.period} ({obj.starttime} to {obj.endtime})</th>
                                                    )
                                                }) : ""
                                            }</>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Obj ? Object.keys(Obj).map(function (key) {
                                            return (
                                                <tr className='text-center' key={key}>
                                                    <td>{key}</td>
                                                    {
                                                        Obj[key].map(function (period, index) {
                                                            if (period.Subject == "Lunch") {
                                                                return (
                                                                    <td key={index}>{period.Subject}</td>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <td id={`${key}${index}`} key={index}>{period.Subject}/By:-{period.Teacher}</td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tr>
                                            )
                                        }) : ""
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <p>Copyright © 2024 Academic Organizer.</p>
    </footer>
</div>
  )
}

export default ShowTimetablecomponent
