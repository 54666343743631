import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Firebase from '../Firebase'
import Timetablee from './Timetablee'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import JsPDF from 'jspdf'
const CreateTimetable = () => {
    const [Data, SetData] = useState({})
    const [Obj, SetObj] = useState({})
    const [timetable, settimetable] = useState({})
    const [Lunch, SetLunch] = useState("")
    const [Condition, SetCondition] = useState(false)
    const [Periods, SetPeriods] = useState("")
    const [Days, SetDays] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"])
    var { Class } = useParams()
    useEffect(function () {
        Firebase.child(`SchoolTimetable`).once("value", function (snap) {
            if (snap.val() == null) {
                SetObj({})
            }
            else {
                SetObj(snap.val())
            }
        })
        Firebase.child(`SchoolTimetable/Classes/${Class}`).once("value", function (snap) {
            if (snap.val() == null) {
                SetData({})
            }
            else {
                SetData(snap.val())
            }
        })
    }, [])
    useEffect(function () {
        var array = []
        if (Object.keys(Obj).length == 0) {

        }
        else {
            var time = Obj.SchoolDetails.SchoolTimings.split(":")
            var hour = parseInt(time[0])
            var minute = parseInt(time[1])
            if (parseInt(Obj.SchoolDetails.PeriodsDay) % 2 != 0) {
                var lunch = (parseInt(Obj.SchoolDetails.PeriodsDay / 2) + 2)
            }
            else {
                var lunch = (Obj.SchoolDetails.PeriodsDay / 2) + 1
            }
            SetLunch(lunch)
            for (var i = 1; i <= parseInt(Obj.SchoolDetails.PeriodsDay); i++) {
                var objects = {}
                if (lunch == i) {
                    objects.starttime = `${hour}:${minute}`
                    minute = parseInt(minute) + 20
                    Obj.SchoolDetails.PeriodsDay = parseInt(Obj.SchoolDetails.PeriodsDay) + 1
                    if (minute >= 60) {
                        minute = minute - 60
                        if (minute == 0) {
                            minute = "00"
                        }
                        hour = hour + 1
                    }
                    objects.endtime = `${hour}:${minute}`
                    objects.period = "Lunch"
                }
                else {
                    if (lunch < i) {
                        objects.starttime = `${hour}:${minute}`
                        minute = parseInt(minute) + parseInt(Obj.SchoolDetails.PeriodsDuration)
                        if (minute >= 60) {
                            minute = minute - 60
                            if (minute == 0) {
                                minute = "00"
                            }
                            hour = hour + 1
                        }
                        objects.endtime = `${hour}:${minute}`
                        objects.period = i - 1
                    }
                    else {
                        objects.starttime = `${hour}:${minute}`
                        minute = parseInt(minute) + parseInt(Obj.SchoolDetails.PeriodsDuration)
                        if (minute >= 60) {
                            minute = minute - 60
                            if (minute == 0) {
                                minute = "00"
                            }
                            hour = hour + 1
                        }
                        objects.endtime = `${hour}:${minute}`
                        objects.period = i
                    }
                }
                array.push(objects)
            }
        }
        SetPeriods(array)
    }, [Obj])
    useEffect(function () {
        if (Object.keys(Obj).length == 0) {

        }
        else {
            var objs = {}
            Days.map(function (day) {
                var obj = []
                Periods.map(function (period, index) {
                    if (period.period == "Lunch") {
                        obj[index] = {
                            Subject: "Lunch"
                        }
                    }
                    else {
                        if (index < Lunch - 1) {
                            var subs = Object.keys(Data.Subjects)[Math.floor(Math.random() * Object.keys(Data.Subjects).length)]
                            var teacherkeys = Object.keys(Obj.TeachersList)
                            for (var i = 0; i < teacherkeys.length; i++) {
                                if (Obj.TeachersList[teacherkeys[i]].Specialist == subs) {
                                    obj[index] = {
                                        Subject: subs,
                                        Teacher: Obj.TeachersList[teacherkeys[i]].Name
                                    }
                                }
                            }
                        }
                        else {
                            var subs = Object.keys(Data.Subjects)[Math.floor(Math.random() * Object.keys(Data.Subjects).length)]
                            var teacherkeys = Object.keys(Obj.TeachersList)
                            for (var i = 0; i < teacherkeys.length; i++) {
                                if (Obj.TeachersList[teacherkeys[i]].Specialist == subs) {
                                    obj[index] = {
                                        Subject: subs,
                                        Teacher: Obj.TeachersList[teacherkeys[i]].Name
                                    }
                                }
                            }
                        }
                    }
                })
                objs[day] = obj
            })
            settimetable({ ...objs })
        }
    }, [Obj, Periods, Days])
    function generatePDF() {
        var timetable = document.getElementById("table")
        const report = new JsPDF('landscape', 'px', [700, 2100]);
        report.html(timetable).then(() => {
            report.save('Timetable.pdf');
        })
    }
    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Time Table</h3>
                        </div>
                    </div>
                </div>
                {
                    Condition ? <Timetablee timetable={timetable} lunch={Lunch} Periods={Periods} Days={Days} obj={Obj} subjects={Data} class={Class} /> :
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card card-table">
                                    <div className="card-body">
                                        <div className="page-header">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h3 className="page-title">Time Table</h3>
                                                </div>
                                                <div className="col-auto text-end float-end ms-auto download-grp">
                                                    <ReactHTMLTableToExcel
                                                        table="table"
                                                        filename="Timetable"
                                                        sheet="Timetable"
                                                        className="btn btn-outline-primary me-2"
                                                        buttonText="Download Excel">
                                                    </ReactHTMLTableToExcel>
                                                    <a onClick={generatePDF} className="btn btn-outline-primary me-2"><i className="fas fa-download" /> Download</a>
                                                    <Link to="/AddClasses" className="btn btn-primary"><i className="fas fa-plus" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id='table' className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                                                <thead className="student-thread">
                                                    <tr className='text-center'>
                                                        <th>Days/Periods</th>
                                                        <>
                                                            {
                                                                Periods ? Periods.map(function (obj, index) {
                                                                    return (
                                                                        <th key={index}>{obj.period} ({obj.starttime} to {obj.endtime})</th>
                                                                    )
                                                                }) : ""
                                                            }</>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        timetable ? Object.keys(timetable).map(function (key) {
                                                            return (
                                                                <tr className='text-center' key={key}>
                                                                    <td>{key}</td>
                                                                    {
                                                                        timetable[key].map(function (period, index) {
                                                                            if (period.Subject == "Lunch") {
                                                                                return (
                                                                                    <td key={index}>{period.Subject}</td>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <td id={`${key}${index}`} key={index}>{period.Subject}/By:-{period.Teacher}</td>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        }) : ""
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="container text-center">
                                            <button className="btn btn-primary" onClick={() => SetCondition(true)}>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
            <footer>
                <p>Copyright © 2024 Academic Organizer.</p>
            </footer>
        </div>

    )
}

export default CreateTimetable
