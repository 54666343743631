import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Firebase from '../Firebase'

const Signup = () => {
 const[Obj,SetObj]= useState({
  Username:"",
  Email:"",
  Password:""
 })
 var navigate=useNavigate()
 const[Schedule,SetSchedule]=useState({})
 const[Error,SetError]=useState(false)
 var pass=useRef()
 var d=new Date()
 var date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
 useEffect(function()
 {
  Firebase.child("SchoolTimetable/SchoolDetails").on("value",function(snap)
  {
      if(snap.val()==null)
      {
          alert("Add School Details")
          navigate("/Dashboard")
      }
      else
      {
          var period=parseInt(snap.val().PeriodsDay);
          var days=["Monday","Tuesday","Wednesday","Thursday","Friday"]
          var array=[]
          for(var i=0;i<period;i++)
          {
              array.push("free")
          }
          var objects={}
          days.map(function(day)
          {
              objects[day]=array
          })
          SetSchedule({...objects})
      }
  }) 
 },[])
 function check(event)
  {
    if(event.target.value=="")
    {
      pass.current.innerHTML="Enter the Repeat Password"
      pass.current.style.color="red"
      SetError(false)
    }
    else
    {
      if(Obj.Password!="")
      {
        if(Obj.Password==event.target.value)
        {
          pass.current.innerHTML="Strong Password"
          pass.current.style.color="green"
          SetError(true)
        }
        else
        {
          pass.current.innerHTML="Your Password does not match. Enter the correct one"
          pass.current.style.color="red"
          SetError(false)
        }
      } 
      else
      {
        pass.current.innerHTML="Weak Password"
        pass.current.style.color="yellow"
        SetError(false)
      }
    }
  }
 function set(e)
 {
  SetObj({...Obj,[e.target.name]:e.target.value,"Date":date})
 }
 function Submit(e)
 {
  e.preventDefault()
  if(Obj.Username!="" && Obj.Email!="" && Obj.Password!="")
  {
    if(Error==true)
    {
      var obj={...Obj,"Schedule":Schedule}
      Firebase.child("SchoolTimetable/TeachersList").push(obj,err=>{
        if(err)
        {
          alert("Error Occured")
        }
        else
        {
          alert("You have signed in...")
          navigate("/")
        }
      }) 
    }
    else
    {
      alert("Your password does not matched")
    }
  }
  else
  {
    alert("Field is Empty")
  }
 }
  return (
  <div className="main-wrapper login-body">
  <div className="login-wrapper">
    <div className="container">
      <div className="loginbox">
        <div className="login-left">
          <img className="img-fluid" src="assets/img/login.png" alt="Logo" />
        </div>
        <div className="login-right">
          <div className="login-right-wrap">
            <h1>Sign Up</h1>
            <p className="account-subtitle">Enter details to create your account</p>
            <form>
              <div className="form-group">
                <label>Username <span className="login-danger">*</span></label>
                <input onChange={set} className="form-control" type="text" name='Username' />
                <span className="profile-views"><i className="fas fa-user-circle" /></span>
              </div>
              <div className="form-group">
                <label>Email <span className="login-danger">*</span></label>
                <input onChange={set} className="form-control" type="email" name='Email' />
                <span className="profile-views"><i className="fas fa-envelope" /></span>
              </div>
              <div className="form-group">
                <label>Password <span className="login-danger">*</span></label>
                <input onChange={set} className="form-control pass-input" type="password" name='Password'/>
                <span className="profile-views feather-eye toggle-password" />
              </div>
              <div className="form-group">
                <label>Confirm password <span className="login-danger">*</span></label>
                <input onChange={check} className="form-control pass-confirm" type="password" />
                <span className="profile-views feather-eye reg-toggle-password" />
              </div>
              <p ref={pass}></p>
              <div className="dont-have">Already Registered? <Link to="/">Login</Link></div>
              <div className="form-group mb-0">
                <button className="btn btn-primary btn-block" type="submit" onClick={Submit}>Register</button>
              </div>
            </form>
            {/* <div className="login-or">
              <span className="or-line" />
              <span className="span-or">or</span>
            </div>
            <div className="social-login">
              <a href="#"><i className="fab fa-google-plus-g" /></a>
              <a href="#"><i className="fab fa-facebook-f" /></a>
              <a href="#"><i className="fab fa-twitter" /></a>
              <a href="#"><i className="fab fa-linkedin-in" /></a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Signup
