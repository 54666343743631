import React, { useEffect, useState } from 'react'
import * as xlsx from "xlsx"
import CertificateTemplates from './CertificateTemplates'
const ShowCertificate = (props) => {
    const[Obj,SetObj]=useState([])
    const[Data,SetData]=useState([])
    const[Condition,SetCondition]=useState(false)
useEffect(function()
{
    const workbook=xlsx.read(props.data,{type: 'buffer'})
    const worksheetName=workbook.SheetNames[0]
    const worksheet=workbook.Sheets[worksheetName]
    const obj= xlsx.utils.sheet_to_json(worksheet)
    SetObj(obj)
},[]) 
useEffect(function()
{
    if(Obj.length!=0)
    {
        SetData(Object.keys(Obj[0]))
    }
},[Obj])
  return (
    <div>
        {
            Condition?<CertificateTemplates data={Obj}/>:
            <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Teachers</h3>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                  <thead className="student-thread">
                    <tr>
                      {
                        Data?Data.map(function(key,index)
                        {
                            return(
                                <th key={index}>{key}</th>
                            )
                        }):""
                      }
                    </tr>
                  </thead>
              <tbody>
                      {
                        Data?Obj.map(function(obj,index)
                        {
                           return(
                            <tr key={index}>
                            <>
                            {
                                Object.keys(obj).map(function(key,i)
                                {
                                    if(key=="Date")
                                    {
                                        const { getJsDateFromExcel } = require("excel-date-to-js");
                                        var date=getJsDateFromExcel(obj[key]);
                                        return(
                                            <td key={i}>{date.getDate()}/{date.getMonth()+1}/{date.getFullYear()}</td>
                                        )
                                    }
                                    else
                                    {
                                        return(
                                            <td key={i}>{obj[key]}</td>
                                        )
                                    }
                                }) 
                                }</>
                            </tr>
                           )
                        }):""
                      }
              </tbody>
                </table>
              </div>
            </div>
            <div className='text-center'>
                <button className='btn btn-primary' onClick={()=>SetCondition(true)}>Next</button>
            </div>
          </div>
        </div>
      </div>
        }
      </div>
  )
}

export default ShowCertificate
