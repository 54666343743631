import React, { useEffect, useState } from 'react'
import Firebase from '../Firebase'

const DasboardComponent = () => {
  const[Obj,SetObj]=useState({})
  const[Obj1,SetObj1]=useState({})
  const[Count,SetCount]=useState(0)
  const[NewTeacher,SetNewTeacher]=useState([])
  var d=new Date()
  var year=d.getFullYear()
  var month=d.getMonth()+1
  var date=d.getDate()
  useEffect(function()
  {
    Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetObj({})
      }
      else
      {
        SetObj(snap.val())
      }
    })
    Firebase.child("SchoolTimetable/Classes").on("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetObj1({})
      }
      else
      {
        SetObj1(snap.val())
      }
    })
  },[])
  useEffect(function()
  {
    if(Object.keys(Obj).length!=0)
    {
      var count=0
      Object.keys(Obj).map(function(key)
      {
        if(Obj[key].Attendance!=null)
        {
          if(Obj[key].Attendance[year]!=null)
          {
            if(Obj[key].Attendance[year][month]!=null)
          {
            if(Obj[key].Attendance[year][month][date]!=null)
          {
              count=count+1
          }
          }
          }
        }
      })
      SetCount(count)
    }
    if(Object.keys(Obj).length!=0)
    {
     var result= Object.keys(Obj).filter(function(key)
      {
        if(parseInt(Obj[key].Date.split("/")[2])==year)
        {
          return parseInt(Obj[key].Date.split("/")[1])==month
        }
      })
      SetNewTeacher(result)
    }
  },[Obj])
  return (
    <div className="page-wrapper">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-sub-header">
            <h3 className="page-title">Welcome Admin!</h3>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className="card bg-comman w-100">
          <div className="card-body">
            <div className="db-widgets d-flex justify-content-between align-items-center">
              <div className="db-info">
                <h6>Teachers</h6>
                <h3>{Obj?Object.keys(Obj).length:0}</h3>
              </div>
              <div className="db-icon">
                <img src="assets/img/icons/dash-icon-01.svg" alt="Dashboard Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className="card bg-comman w-100">
          <div className="card-body">
            <div className="db-widgets d-flex justify-content-between align-items-center">
              <div className="db-info">
                <h6>Teacher's Present</h6>
                <h3>{Count?Count:0}</h3>
              </div>
              <div className="db-icon">
                <img src="assets/img/icons/dash-icon-02.svg" alt="Dashboard Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className="card bg-comman w-100">
          <div className="card-body">
            <div className="db-widgets d-flex justify-content-between align-items-center">
              <div className="db-info">
                <h6>Classes</h6>
                <h3>{Obj1?Object.keys(Obj1).length:0}</h3>
              </div>
              <div className="db-icon">
                <img src="assets/img/icons/dash-icon-03.svg" alt="Dashboard Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className="card bg-comman w-100">
          <div className="card-body">
            <div className="db-widgets d-flex justify-content-between align-items-center">
              <div className="db-info">
                <h6>New Teacher</h6>
                <h3>{NewTeacher?NewTeacher.length:0}</h3>
              </div>
              <div className="db-icon">
                <img src="assets/img/icons/dash-icon-04.svg" alt="Dashboard Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-12 d-flex">
        <div className="card flex-fill student-space comman-shadow">
          <div className="card-header d-flex align-items-center">
            <h5 className="card-title">New Teachers</h5>
            <ul className="chart-list-out student-ellips">
              <li className="star-menus"><a href="#"><i className="fas fa-ellipsis-v" /></a></li>
            </ul>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table star-student table-hover table-center table-borderless table-striped">
                <thead className="thead-light">
                  <tr className='text-center'>
                    <th className="text-center">S.No</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Phone Number</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">Main Subject</th>
                    <th className="text-center">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Obj?Object.keys(Obj).reverse().map(function(key,index)
                    {
                      if(index<10)
                      {
                        return(
                          <tr className="text-center" key={key}>
                              <td>{index+1}</td>
                              <td>{Obj[key].Name?Obj[key].Name:""}</td>
                              <td>{Obj[key].Phone?Obj[key].Phone:""}</td>
                              <td>{Obj[key].Email?Obj[key].Email:""}</td>
                              <td>{Obj[key].Specialist?Obj[key].Specialist:""}</td>
                              <td>{Obj[key].Date?Obj[key].Date:""}</td>
                          </tr>
                        )
                      }
                    }):""
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-xl-6 d-flex">
        <div className="card flex-fill comman-shadow">
          <div className="card-header d-flex align-items-center">
            <h5 className="card-title ">Student Activity </h5>
            <ul className="chart-list-out student-ellips">
              <li className="star-menus"><a href="#"><i className="fas fa-ellipsis-v" /></a></li>
            </ul>
          </div>
          <div className="card-body">
            <div className="activity-groups">
              <div className="activity-awards">
                <div className="award-boxs">
                  <img src="assets/img/icons/award-icon-01.svg" alt="Award" />
                </div>
                <div className="award-list-outs">
                  <h4>1st place in "Chess”</h4>
                  <h5>John Doe won 1st place in "Chess"</h5>
                </div>
                <div className="award-time-list">
                  <span>1 Day ago</span>
                </div>
              </div>
              <div className="activity-awards">
                <div className="award-boxs">
                  <img src="assets/img/icons/award-icon-02.svg" alt="Award" />
                </div>
                <div className="award-list-outs">
                  <h4>Participated in "Carrom"</h4>
                  <h5>Justin Lee participated in "Carrom"</h5>
                </div>
                <div className="award-time-list">
                  <span>2 hours ago</span>
                </div>
              </div>
              <div className="activity-awards">
                <div className="award-boxs">
                  <img src="assets/img/icons/award-icon-03.svg" alt="Award" />
                </div>
                <div className="award-list-outs">
                  <h4>Internation conference in "St.John School"</h4>
                  <h5>Justin Leeattended internation conference in "St.John School"</h5>
                </div>
                <div className="award-time-list">
                  <span>2 Week ago</span>
                </div>
              </div>
              <div className="activity-awards mb-0">
                <div className="award-boxs">
                  <img src="assets/img/icons/award-icon-04.svg" alt="Award" />
                </div>
                <div className="award-list-outs">
                  <h4>Won 1st place in "Chess"</h4>
                  <h5>John Doe won 1st place in "Chess"</h5>
                </div>
                <div className="award-time-list">
                  <span>3 Day ago</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </div>
  <footer>
    <p>Copyright © 2024 Academic Organizer.</p>
  </footer>
</div>

  )
}

export default DasboardComponent
