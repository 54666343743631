import React, { useState } from 'react'
import ShowCertificate from './ShowCertificate'

const UploadCertificate = () => {
    const[Obj,SetObj]=useState({})
    const[Condition,SetCondition]=useState(false)
    function Upload(e)
    {
        e.preventDefault()
       var input=document.createElement("input")
       input.type="file"
        input.click()
        input.addEventListener("change",function(event){
            if(event.target.files[0])
            {
                var reader=new FileReader()
                reader.readAsArrayBuffer(event.target.files[0])
                reader.onload=function()
                {
                    SetObj(reader.result)
                    SetCondition(true)
                }
            }
            else
            {
                alert("Please select your file")
            }
        })
    }
  return (
   <div>
     <div className="page-wrapper">
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Certificate</h3>
          </div>
        </div>
      </div>
      {
        Condition?<ShowCertificate data={Obj}/>:
        <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <h5 className="form-title"><span>Generate Certificate</span></h5>
                  </div>
                  <div className="col-12">
                    <div className="student-submit">
                      <button className="btn btn-primary" onClick={Upload}>Upload your file</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>
   </div>
  )
}

export default UploadCertificate
