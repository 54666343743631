import React from 'react'
import Header from '../Components/Header'
import EditTeacherComponent from '../Components/EditTeacherComponent'
import '../assets/plugins/bootstrap/css/bootstrap.min.css'
import '../assets/plugins/feather/feather.css'
import '../assets/plugins/icons/flags/flags.css'
import '../assets/plugins/fontawesome/css/fontawesome.min.css'
import '../assets/plugins/fontawesome/css/all.min.css'
import '../assets/css/style.css'
import '../assets/img/favicon.png'
import '../assets/img/favicon.png'
const EditTeacher = () => {
  return (
    <div className="main-wrapper">
      <Header/>
      <EditTeacherComponent/>
    </div>
  )
}

export default EditTeacher
