import React from 'react'
import Header from '../Components/Header'
import ShowTimetablecomponent from '../Components/ShowTimetablecomponent'

const ShowTimetable = () => {
  return (
    <div className='main-wrapper'>
      <Header/>
      <ShowTimetablecomponent/>
    </div>
  )
}

export default ShowTimetable
