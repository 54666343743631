import React, { useState } from 'react'
import '../Style/Style.css'
import { Link } from 'react-router-dom'

const Sidebar = () => {
    const [sidebar, setSidebar] = useState(
        {
            sidebar1:false,
            sidebar2:false,
            sidebar3:false,
            sidebar4:false,
            sidebar5:false,
            sidebar6:false,
            sidebar7:false
        }
      ) 
      function toggle(sidebarname)
      {
        setSidebar({...sidebar,[sidebarname]:!sidebar[sidebarname]})
      }
  return (
    <div className="sidebar" id="sidebar">
    <div className="sidebar-inner slimscroll">

      <div id="sidebar-menu" className="sidebar-menu">
        <ul>
          <li className="menu-title">
            <span>Main Menu</span>
          </li>
          <li className={sidebar.sidebar1 ?"submenu active":"submenu"}>
            <a className={sidebar.sidebar1 ? "subdrop" : ""} onClick={() => toggle("sidebar1")} ><i className="feather-grid" /> <span> Dashboard</span> <span className="menu-arrow" /></a>
            <div className={sidebar.sidebar1 ? "sub open" : "sub notopen"} >
              <div ><Link to="/Dashboard">Admin Dashboard</Link></div>
              {/* <div><Link to="/TeacherDashboard">Teacher Dashboard</Link></div> */}
              {/* <div><Link to="/StudentDashboard">Student Dashboard</Link></div> */}
            </div>
          </li>
          {/* <li className={sidebar.sidebar2 ?"submenu active":"submenu"}>
            <a className={sidebar.sidebar2 ? "subdrop" : ""} onClick={() => toggle("sidebar2")} ><i className="fas fa-graduation-cap" /> <span> Students</span> <span className="menu-arrow" /></a>
            <div className={sidebar.sidebar2 ? "sub open" : " sub notopen"} >
              <div><Link to="/">Student List</Link></div>
              <div><Link to="/">Student View</Link></div>
              <div><Link to="/">Student Add</Link></div>
              <div><Link to="/">Student Edit</Link></div>
            </div>
          </li> */}
          <li className={sidebar.sidebar3 ?"submenu active":"submenu"}>
            <a className={sidebar.sidebar3 ? "subdrop" : ""} onClick={() => toggle("sidebar3")}><i className="fas fa-chalkboard-teacher" /> <span> Teachers</span> <span className="menu-arrow" /></a>
            <div className={sidebar.sidebar3 ? "sub open" : " sub notopen"} >
              <div><Link to="/TeacherList">Teachers List</Link></div>
              {/* <div><a href="teacher-details.html">Teacher View</a></div> */}
              <div><Link to="/AddTeacher">Add Teachers</Link></div>
              {/* <div><Link to="/EditTeacher">Teacher Edit</Link></div> */}
            </div>
          </li>
          <li className={sidebar.sidebar4 ?"submenu active":"submenu"}>
            <a className={sidebar.sidebar4 ? "subdrop" : ""} onClick={() => toggle("sidebar4")} ><i className="fas fa-building" /> <span> Classes</span> <span className="menu-arrow" /></a>
            <div className={sidebar.sidebar4 ? "sub open" : " sub notopen"} >
              <div><Link to="/Classes">Classes List</Link></div>
              <div><Link to="/AddClasses">Add Classes</Link></div>
              {/* <div><Link to="/">Department Edit</Link></div> */}
            </div>
          </li>
          {/* <li className={sidebar.sidebar5 ?"submenu active":"submenu"}>
            <a className={sidebar.sidebar5 ? "subdrop" : ""} onClick={() => toggle("sidebar5")} ><i className="fas fa-book-reader" /> <span> Subjects</span> <span className="menu-arrow" /></a>
            <div className={sidebar.sidebar5 ? "sub open" : " sub notopen"} >
              <div><Link to="/">Subject List</Link></div>
              <div><Link to="/">Subject Add</Link></div>
              <div><Link to="/">Subject Edit</Link></div>
            </div>
          </li> */}
          <li className={sidebar.sidebar6 ?"submenu active":"submenu"}>
            <a className={sidebar.sidebar6 ? "subdrop" : ""} onClick={() => toggle("sidebar6")} ><i className="fas fa-clipboard" /> <span> Attendance</span> <span className="menu-arrow" /></a>
            <div className={sidebar.sidebar6 ? "sub open" : " sub notopen"} >
              <div><Link to="/TodayAttendance">Today Attendance</Link></div>
              <div><Link to="/MonthAttendance">Month Attendance</Link></div>
              {/* <div><a href="add-invoice.html">Add Invoices</a></div>
              <div><a href="edit-invoice.html">Edit Invoices</a></div>
              <div><a href="view-invoice.html">Invoices Details</a></div>
              <div><a href="invoices-settings.html">Invoices Settings</a></div> */}
            </div>
          </li>
          {/* <li className="menu-title">
            <span>Pages</span>
          </li>
          <li className={sidebar.sidebar7 ?"submenu active":"submenu"}>
            <a className={sidebar.sidebar7 ? "subdrop" : ""} onClick={() => toggle("sidebar7")}><i className="fas fa-shield-alt" /> <span> Authentication </span> <span className="menu-arrow" /></a>
            <div className={sidebar.sidebar7 ? "sub open" : " sub notopen"} >
              <div><a href="login.html">Login</a></div>
              <div><a href="register.html">Register</a></div>
              <div><a href="forgot-password.html">Forgot Password</a></div>
              <div><a href="error-404.html">Error Page</a></div>
              </div>
          </li> */}
           <li>
            <Link to="/Certificate"><i className="fas fa-book-reader" /> <span>Generate Certificate</span></Link>
          </li>
          <li>
            <Link to="/Help"><i className="fas fa-book-reader" /> <span>Help</span></Link>
          </li>
            <li>
            <Link to="/"><i className="fas fa-shield-alt" /> <span>Logout</span></Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  )
}

export default Sidebar