import React, { useEffect, useState } from 'react'
import "./Style.css"
import { useParams } from 'react-router-dom'
import Firebase from '../Firebase'
const Teacher_dashcomp = () => {
  const[Obj,SetObj]= useState({})
  const[Obj1,SetObj1]= useState({})
  const[Title,SetTitle]= useState("")
  const[Duration,SetDuration]=useState(0)
  const[Attendance,SetAttendance]=useState({})
 let d= new Date()
 let year=d.getFullYear()
 let month=d.getMonth()+1
 let date=d.getDate()
 var {key}=useParams()
 useEffect(function()
   {
    Firebase.child(`SchoolTimetable/TeachersList/${key}`).on("value",function(snap)
    {
        if(snap.val()==null)
        {
            SetObj({})
        }
        else
        {
            SetObj(snap.val())
            SetObj1(snap.val())
        }
    })
   },[])
   useEffect(function()
   {
    if(Object.keys(Obj1).length!=0)
    {
      if(Obj1.Attendance==null)
        {
            // everything new
            var obj1={}
            var obj2={}
            var obj3={}
            obj1[date]={Status:"Present"}
            obj2[month]=obj1
            obj3[year]=obj2
            Firebase.child(`SchoolTimetable/TeachersList/${key}/Attendance`).update(obj3)
        }
        else
        {
            // Attendance present
            if(Obj1.Attendance[year]==null)
            {
                var obj4={}
                var obj5={}
                obj4[date]={Status:"Present"}
                obj5[month]=obj4
                Obj1.Attendance[year]=obj5
                Firebase.child(`SchoolTimetable/TeachersList/${key}`).update(Obj1)
                //new year
            }
            else
            {
                if(Obj1.Attendance[year][month]==null)
                {
                    //new month
                    var obj6={}
                    var obj7={}
                    obj6[date]={Status:"Present"}
                    obj7[month]=obj6
                    Firebase.child(`SchoolTimetable/TeachersList/${key}/Attendance/${year}`).update(obj7)

                }
                else
                {
                    var obj8={}
                    obj8[date]={Status:"Present"}
                    Firebase.child(`SchoolTimetable/TeachersList/${key}/Attendance/${year}/${month}`).update(obj8)
                }
            }
        }
    }
    if(Obj.Schedule!=null)
    {
      var title="";
      var count=0
      Object.keys(Obj.Schedule).map(function(key)
      {
        Obj.Schedule[key].map(function(period)
        {
          if(period!="free")
          {
            count=count+1
            title=title+`${period},`
          }
        })
      })
      SetDuration(count)
      if(Title!="")
      {
        SetTitle(title)
      }
    }
    if(Obj1.Attendance!=null)
    {
      if(Obj.Attendance[year]!=null)
      {
        if(Obj.Attendance[year][month]!=null)
        {
          SetAttendance(Obj.Attendance[year][month])
        }
      }
    }
   },[Obj1])
  return (
  <div className="main-wrapper">
  <div className="pagepadding">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-sub-header">
            <h3 className="page-title">Welcome {Obj.Name?Obj.Name:""}!</h3>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className="card bg-comman w-100">
          <div className="card-body">
            <div className="db-widgets d-flex justify-content-between align-items-center">
              <div className="db-info">
                <h6>Today's Attendance</h6>
                <h3>{Obj1.Attendance?Obj1.Attendance[year]?Obj1.Attendance[year][month]?Obj1.Attendance[year][month][date]?Obj1.Attendance[year][month][date].Status?Obj1.Attendance[year][month][date].Status:"Absent":"Absent":"Absent":"Absent":"Absent"}</h3>
              </div>
              <div className="db-icon">
                <img src="../assets/img/icons/teacher-icon-01.svg" alt="Dashboard Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className="card bg-comman w-100">
          <div className="card-body">
            <div className="db-widgets d-flex justify-content-between align-items-center">
              <div className="db-info">
                <h6>Main Subject</h6>
                <h3>{Obj.Specialist?Obj.Specialist:"None"}</h3>
              </div>
              <div className="db-icon">
                <img src="../assets/img/icons/dash-icon-01.svg" alt="Dashboard Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className="card bg-comman w-100">
          <div className="card-body">
            <div className="db-widgets d-flex justify-content-between align-items-center">
              <div className="db-info">
                <h6>Classes</h6>
                <h3>{Title?Title:"None"}</h3>
              </div>
              <div className="db-icon">
                <img src="../assets/img/icons/teacher-icon-02.svg" alt="Dashboard Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className="card bg-comman w-100">
          <div className="card-body">
            <div className="db-widgets d-flex justify-content-between align-items-center">
              <div className="db-info">
                <h6>Total Hours</h6>
                <h3>{Duration?Duration:0}</h3>
              </div>
              <div className="db-icon">
                <img src="../assets/img/icons/teacher-icon-03.svg" alt="Dashboard Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-lg-12 col-xl-8">
        <div className="row">
          <div className="col-12 col-lg-12 col-xl-12 d-flex">
            <div className="card flex-fill comman-shadow">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h5 className="card-title">Your Schedule</h5>
                  </div>
                </div>
              </div>
              <div className="pt-3 pb-3">
                <div className="table-responsive lesson">
                  <table className="table table-hover table-bordered table-striped table-condensed table-center">
                    <thead>
                      <tr>
                        <th>Days/Periods</th>
                        <>
                        {
                          Obj.Schedule?Obj.Schedule["Monday"].map(function(title,index)
                          {
                            return(
                              <th className='text-center' key={index}>{index+1}</th>
                            )
                          }):""
                        }</>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Obj.Schedule?(Object.keys(Obj.Schedule).map(function(day)
                        {
                          return(
                            <tr key={day}>
                              <td>{day}</td>
                              <>
                              {
                              Obj.Schedule[day].map(function(title,index)
                              {
                                return(
                                  <td className='text-center' key={index}>{title}</td>
                                )
                              })}
                              </>
                            </tr>
                          )
                        })):""
                      }
                      {/* <tr>
                        <td>
                          <div className="date">
                            <b>Lessons 30</b>
                            <p>3.1 Ipsuum dolor</p>
                            <ul className="teacher-date-list">
                              <li><i className="fas fa-calendar-alt me-2" />Sep 5, 2022</li>
                              <li>|</li>
                              <li><i className="fas fa-clock me-2" />09:00 - 10:00 am</li>
                            </ul>
                          </div>
                        </td>
                        <td>
                          <div className="lesson-confirm">
                            <a href="#">Confirmed</a>
                          </div>
                          <button type="submit" className="btn btn-info">Reschedule</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="date">
                            <b>Lessons 30</b>
                            <p>3.1 Ipsuum dolor</p>
                            <ul className="teacher-date-list">
                              <li><i className="fas fa-calendar-alt me-2" />Sep 5, 2022</li>
                              <li>|</li>
                              <li><i className="fas fa-clock me-2" />09:00 - 10:00 am</li>
                            </ul>
                          </div>
                        </td>
                        <td>
                          <div className="lesson-confirm">
                            <a href="#">Confirmed</a>
                          </div>
                          <button type="submit" className="btn btn-info">Reschedule</button>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-xl-12 d-flex">
            <div className="card flex-fill comman-shadow">
              <div className="card-header d-flex align-items-center">
                <h5 className="card-title">Your Attendance</h5>
                <ul className="chart-list-out student-ellips">
                  <li className="star-menus"><a><i className="fas fa-ellipsis-v" /></a></li>
                </ul>
              </div>
              <div className="card-body">
                <div className="teaching-card">
                  <table className='table table-bordered table-hover table-striped table-condensed'>
                    <thead>
                      <tr>
                       <>
                       {
                          Attendance?Object.keys(Attendance).map(function(key,index)
                          {
                            return(
                              <th key={index}>{`${key}-${month}-${year}`}</th>
                            )
                          }):""
                        }</>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {
                          Attendance?Object.keys(Attendance).map(function(key,index)
                          {
                            return(
                                <td key={index}>{Attendance[key].Status?Attendance[key].Status:""}</td>
                            )
                          }):""
                        }
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12 col-xl-4 d-flex">
        <div className="card flex-fill comman-shadow">
        <div className="card-body">
                  <div className="heading-detail">
                    <h4>Personal Details :</h4>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-user" />
                    </div>
                    <div className="views-personal">
                      <h4>Name</h4>
                      <h5>{Obj.Name?Obj.Name:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <img src="../assets/img/icons/buliding-icon.svg"/>
                    </div>
                    <div className="views-personal">
                      <h4>Qualification </h4>
                      <h5>{Obj.Qualification?Obj.Qualification:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-phone-call" />
                    </div>
                    <div className="views-personal">
                      <h4>Mobile</h4>
                      <h5>{Obj.Phone?Obj.Phone:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-mail" />
                    </div>
                    <div className="views-personal">
                      <h4>Email</h4>
                      <h5><a href={Obj.Email?`mailto:${Obj.Email}`:""}>{Obj.Email?Obj.Email:""}</a></h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-user" />
                    </div>
                    <div className="views-personal">
                      <h4>Gender</h4>
                      <h5>{Obj.Gender?Obj.Gender:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-calendar" />
                    </div>
                    <div className="views-personal">
                      <h4>Date of Birth</h4>
                      <h5>{Obj.DOB?Obj.DOB:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-italic" />
                    </div>
                    <div className="views-personal">
                      <h4>Other Subjects</h4>
                      <h5>{Obj.OtherSubject?Obj.OtherSubject:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity mb-0">
                    <div className="personal-icons">
                      <i className="feather-map-pin" />
                    </div>
                    <div className="views-personal">
                      <h4>Address</h4>
                      <h5>{Obj.Address?`${Obj.Address},${Obj.City}-${Obj.ZipCode},${Obj.State}`:""}</h5>
                    </div>
                  </div>
                </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <p>Copyright © 2024 Academic Orgainzer.</p>
  </footer>
</div>
  </div>
  )
}

export default Teacher_dashcomp