import React, { useEffect, useState } from 'react'
import Firebase from '../Firebase'
import { Link, useNavigate } from 'react-router-dom'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import JsPDF from 'jspdf';
const Teacher = () => {
  const[Obj,SetObj]=useState({})
  const[Obj1,SetObj1]=useState({})
  var navigate=useNavigate()
  useEffect(function()
  {
    Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetObj({})
        SetObj1({})
      }
      else
      {
        SetObj(snap.val())
        SetObj1(snap.val())
      }
    })
  },[])
  function View(key)
  {
    navigate(`/TeacherView/${key}`)
  }
  function Delete(key)
  {
   var result= window.confirm("Do you want to delete?")
   if(result==true)
   {
      Firebase.child(`SchoolTimetable/TeachersList/${key}`).remove(err=>{
        if(err)
        {
          alert("Error Occured")
        }
        else
        {
          alert("Teacher Deleted")
        }
      })
   }
  }
  function generatePDF()
  {
  var arr= document.getElementById("table")
  const report = new JsPDF('landscape','px',[1300,1330]);
  report.html(arr).then(() => {
      report.save('Teacherlist.pdf');
  });
  }
  function search(event)
   {
        if(event.target.value=="")
        {
            SetObj(Obj1)
        }
        else
        {   var obj={}
            Object.keys(Obj1).map(function(key)
            {
              if(Obj1[key].Name!=null)
              { 
                if(Obj1[key].Phone!=null)
                {
                  if(Obj1[key].Qualification!=null)
                  {
                    if(Obj1[key].Name.match(event.target.value[0].toUpperCase()+event.target.value.slice(1)) || Obj1[key].Phone.match(event.target.value) || Obj1[key].Qualification.match(event.target.value))
                {
                    obj[key]=Obj1[key]
                }
                  }
                }
              }
            })
            SetObj(obj)
        }
   }
  return (
   <div className="page-wrapper">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Teachers</h3>
        </div>
      </div>
    </div>
    <div className="student-group-form">
      <div className="row">
        <div className="col-lg-11 col-md-11">
          <div className="form-group">
            <input type="text" className="form-control" onChange={search} placeholder="Search by Name, Phone Number, Qualification" />
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-table">
          <div className="card-body">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Teachers</h3>
                </div>
                <div className="col-auto text-end float-end ms-auto download-grp">
                <ReactHTMLTableToExcel
                    table="table"
                    filename="TeachersDetail"
                    sheet="TeachersDetail"
                    className="btn btn-outline-primary me-2"
                    buttonText="Download Excel">
                    </ReactHTMLTableToExcel>
                  <button onClick={generatePDF} className="btn btn-outline-primary me-2"><i className="fas fa-download" /> Download Pdf</button>
                  <Link to="/AddTeacher" className="btn btn-primary"><i className="fas fa-plus" /></Link>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table id='table' className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                <thead className="student-thread">
                  <tr>
                    <th className='text-center'>S.No</th>
                    <th className='text-center'>Name</th>
                    <th className='text-center'>Phone Number</th>
                    <th className='text-center'>Gender</th>
                    <th className='text-center'>Main Subject</th>
                    <th className='text-center'>Other Subjects</th>
                    <th className='text-center'>Joining Date</th>
                    <th className='text-center'>Qualification</th>
                    <th className='text-center'>Address</th>
                    <th className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Obj?Object.keys(Obj).reverse().map(function(key,index)
                    {
                    return(
                      <tr className='text-center' key={key}>
                      <td>{index+1}</td>
                      <td>{Obj[key].Name?Obj[key].Name:""}</td>
                      <td>{Obj[key].Phone?Obj[key].Phone:""}</td>
                      <td>{Obj[key].Gender?Obj[key].Gender:""}</td>
                      <td>{Obj[key].Specialist?Obj[key].Specialist:""}</td>
                      <td>{Obj[key].OtherSubject?Obj[key].OtherSubject:""}</td>
                      <td>{Obj[key].Date?Obj[key].Date:""}</td>
                      <td>{Obj[key].Qualification?Obj[key].Qualification:""}</td>
                      <td>{Obj[key].Address?Obj[key].Address:""},{Obj[key].City?Obj[key].City:""}</td>
                      <td className="text-end">
                        <div className="actions">
                          <a onClick={()=>View(key)} className="btn btn-sm bg-success-light me-2">
                            <i className="feather-eye" />
                          </a>
                          <a onClick={()=>navigate(`/EditTeacher/${key}`)} className="btn btn-sm bg-danger-light">
                            <i className="feather-edit" />
                          </a>
                          <a onClick={()=>Delete(key)} className="btn btn-sm bg-danger-light">
                          <i className="feather-trash" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    )
                    }):<tr>
                      <td colSpan={10}>No Record Found</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <p>Copyright © 2024 Academic Organizer.</p>
  </footer>
</div>

  )
}

export default Teacher
