import React from 'react'
import { useNavigate } from 'react-router-dom'

const HelpComponent = () => {
  var navigate=useNavigate()
  return (
    <div className="main-wrapper">
   <div className="pagepadding">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row">
        <div className="col">
          <h3 className="page-title">Overview</h3>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <ul className="timeline">
              <li>
                <div className="timeline-badge success">
                  <i className="fas fa-user" />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Create your Institute Account</h4>
                  </div>
                  <div className="timeline-body">
                    <p>The first step is to create your Institute Account. In that section, you have to fill necessary and basic details of your Institute like Institute Name, Phone Number, Email ID. Also you will have to enter correct School Timings,Periods held per day, Periods Duration and more...</p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-badge warning">
                  <i className="fas fa-users" /></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Adding Teachers </h4>
                  </div>
                  <div className="timeline-body">
                    <p> After saving Institute details,you will have to add teachers from Add Teachers panel appearing in Sidebar in left side and also you can see them in Teachers List panel in Sidebar. When you are creating Teachers Account you will have to mention necessary details like Name,Mobile Number,Email ID,Username and Password and they main subjects in which they are comfortable.</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-badge danger">
                  <i className="fas fa-gift" />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Teachers Schedule</h4>
                  </div>
                  <div className="timeline-body">
                    <p>There is a option given in your sidebar of making new classes and showing and deleting present classes, you can create new class by entering necessary details like classname,head of class(teacher's incharge) and subjects. After adding class you have to create their timetable option given in class list in Action column. You can adjust your teachers by clicking on them. </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Showing Timetable</h4>
                  </div>
                  <div className="timeline-body">
                    <p>After saving your timetable error free, you can also view them in class list after clicking on view button in Action Column. and download it in excel and pdf form. Admin can see teacher's Schedule in teachers list after clicking on view button given in Action Column.</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-badge info">
                  <i className="fa fa-save" />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Attendance</h4>
                  </div>
                  <div className="timeline-body">
                    <p>When the teacher login from Login form with their Username and Password, there present will marked for that day which day he/she Login. Admin can view the Attendance of all the Teachers of Month in Month Attendace panel given in Sidebar and they can download them in the form of both Excel and Pdf. </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-badge success">
                  <i className="fa fa-graduation-cap" />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Certificates Generator</h4>
                  </div>
                  <div className="timeline-body">
                    <p> We have also given an option to create certificates after upload excel file, the data required for certificates is Name,Phone Number,Email ID,Courses they have done,Date when they have done and score they have Scored. After uploading, you will chose one template from given templates(atleast five templates). By clicking on them, certificates will be generated, you can download them in the form of pdf and also you can print them.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="text-center">
          <button className='btn btn-primary' onClick={()=>navigate("/Dashboard")}>Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <p>Copyright © 2024 Academic Organizer.</p>
  </footer>
</div>
</div>
  )
}

export default HelpComponent
