import React, { useEffect, useState } from 'react'
import Firebase from '../Firebase'
import { useNavigate } from 'react-router-dom'

const AllClasses = () => {
 const[Obj,SetObj]= useState({})
 const[Obj1,SetObj1]= useState({})
 const[Data,SetData]=useState({})
 var navigate=useNavigate()
 useEffect(function()
 {
  Firebase.child("SchoolTimetable/Classes").on("value",function(snap)
  {
    if(snap.val()==null)
    {
      SetObj({})
      SetObj1({})
    }
    else
    {
      SetObj(snap.val())
      SetObj1(snap.val())
    }
  })
  Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
  {
    if(snap.val()==null)
    {
      SetData({})
    }
    else
    {
      SetData(snap.val())
    }
  })
 },[])
 function search(event)
 {
      if(event.target.value=="")
      {
          SetObj(Obj1)
      }
      else
      {   var obj={}
          Object.keys(Obj1).map(function(key)
          {
              if(Obj1[key].ClassName.match(event.target.value) || Obj1[key].Head.match(event.target.value))
              {
                  obj[key]=Obj1[key]
              }
          })
          SetObj(obj)
      }    
 }
 function Del(key)
 {
  var result= window.confirm("Do you want to delete the Class?")
   if(result==true)
   {
    
    Object.keys(Data).map(function(keyss)
    {
      Object.keys(Data[keyss].Schedule).map(function(day)
      {
        Data[keyss].Schedule[day].map(function(period,index)
        {
          if(period==Obj[key].ClassName)
          {
            Data[keyss].Schedule[day][index]="free"
          }
        })
      })
    })
    Firebase.child("SchoolTimetable/TeachersList").update(Data,err=>{
      if(err)
      {
        alert("Error Occured in Updating Teachers Schedule")
      }
    })
      Firebase.child(`SchoolTimetable/Classes/${key}`).remove(err=>{
        if(err)
        {
          alert("Error Occured")
        }
        else
        {
          alert("Class Deleted")
        }
      })
   }
 }
  return (
 <div className="page-wrapper">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Classes</h3>
        </div>
      </div>
    </div>
    <div className="student-group-form">
      <div className="row">
        <div className="col-lg-11 col-md-11">
          <div className="form-group">
            <input type="text" onChange={search} className="form-control" placeholder="Search by Class Name or Head ..." />
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-table">
          <div className="card-body">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Classes</h3>
                </div>
              </div>
            </div>
            <div className='scroll'>
            <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
              <thead className="student-thread">
                <tr>
                  <th className="text-center">S.No.</th>
                  <th className="text-center">Class Name</th>
                  <th className="text-center">Head of Class</th>
                  <th className="text-center">Number of Subjects</th>
                  <th className="text-center">Subjects</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
               {
                Obj?Object.keys(Obj).reverse().map(function(key,index)
                {
                  if(Obj[key].Timetable==null)
                  {
                    var title=""
                    Object.keys(Obj[key].Subjects).map(function(sub)
                    {
                      title=title+`${sub},`
                    })
                        return(
                        <tr className='text-center' key={key}>
                        <td>{index+1}</td>
                        <td>{Obj[key].ClassName}</td>
                        <td>{Obj[key].Head}</td>
                        <td>{Obj[key].NumberofSubjects}</td>
                        <td>{title}</td>
                        <td>
                        <div className="actions">
                            {/* <a className="btn btn-sm bg-success-light me-2">
                              <i className="feather-eye" />
                            </a> */}
                            <a onClick={()=>navigate(`/CreateTimetable/${key}`)} className="btn btn-sm bg-danger-light">
                              <i className="feather-edit" />
                            </a>
                            {/* <a className="btn btn-sm bg-danger-light">
                            <i className="feather-trash" />
                            </a> */}
                          </div>
                        </td>
                      </tr>
                    )
                  }
                  else
                  {
                    var title=""
                    Object.keys(Obj[key].Subjects).map(function(sub)
                    {
                      title=title+`${sub},`
                    })
                        return(
                        <tr className='text-center' key={key}>
                        <td>{index+1}</td>
                        <td>{Obj[key].ClassName}</td>
                        <td>{Obj[key].Head}</td>
                        <td>{Obj[key].NumberofSubjects}</td>
                        <td>{title}</td>
                        <td className='text-center'>
                        <div className="actions">
                            <a onClick={()=>navigate(`/ShowTimetable/${key}`)} className="btn btn-sm bg-success-light me-2">
                              <i className="feather-eye" />
                            </a>
                            {/* <a onClick={()=>navigate(`/CreateTimetable/${key}`)} className="btn btn-sm bg-danger-light">
                              <i className="feather-edit" />
                            </a> */}
                            <a onClick={()=>Del(key)} className="btn btn-sm bg-danger-light">
                            <i className="feather-trash" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                }):""
               }
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <p>Copyright © 2024 Academic Organizer.</p>
  </footer>
</div>

  )
}

export default AllClasses
