import React from 'react'
import Header from '../Components/Header'
import CreateTimetable from '../Components/CreateTimetable'

const Timetable = () => {
  return (
    <div className='main-wrapper'>
      <Header/>
      <CreateTimetable/>
    </div>
  )
}

export default Timetable
