import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Firebase from '../Firebase'
import "./Style.css"
const TeacherViewComponent = () => {
    const[Obj,SetObj]=useState({})
    var {key}=useParams()
    useEffect(function()
    {
        Firebase.child(`SchoolTimetable/TeachersList/${key}`).on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetObj({})
            }
            else
            {
                SetObj(snap.val())
            }
        })
    },[])
  return (
    <div className="page-wrapper">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-sub-header">
            <h3 className="page-title">Teachers Details</h3>
          </div>
        </div>
      </div>
    </div>
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <div className="about-info">
              <h4>Profile <span><a><i className="feather-more-vertical" /></a></span></h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="student-personals-grp">
              <div className="card">
                <div className="card-body">
                  <div className="heading-detail">
                    <h4>Personal Details :</h4>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-user" />
                    </div>
                    <div className="views-personal">
                      <h4>Name</h4>
                      <h5>{Obj.Name?Obj.Name:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <img src="../assets/img/icons/buliding-icon.svg" />
                    </div>
                    <div className="views-personal">
                      <h4>Main Subject </h4>
                      <h5>{Obj.Specialist?Obj.Specialist:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-phone-call" />
                    </div>
                    <div className="views-personal">
                      <h4>Mobile</h4>
                      <h5>{Obj.Phone?Obj.Phone:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-mail" />
                    </div>
                    <div className="views-personal">
                      <h4>Email</h4>
                      <h5><a href={Obj.Email?`mailto:${Obj.Email}`:""}>{Obj.Email?Obj.Email:""}</a></h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-user" />
                    </div>
                    <div className="views-personal">
                      <h4>Gender</h4>
                      <h5>{Obj.Gender?Obj.Gender:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-calendar" />
                    </div>
                    <div className="views-personal">
                      <h4>Date of Birth</h4>
                      <h5>{Obj.DOB?Obj.DOB:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="feather-italic" />
                    </div>
                    <div className="views-personal">
                      <h4>Other Subjects</h4>
                      <h5>{Obj.OtherSubject?Obj.OtherSubject:""}</h5>
                    </div>
                  </div>
                  <div className="personal-activity mb-0">
                    <div className="personal-icons">
                      <i className="feather-map-pin" />
                    </div>
                    <div className="views-personal">
                      <h4>Address</h4>
                      <h5>{Obj.Address?`${Obj.Address},${Obj.City}-${Obj.ZipCode},${Obj.State}`:""}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="student-personals-grp">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="heading-detail">
                    <h4>Schedule</h4>
                  </div>
                  <div className='scroll'>
                    <table className='table table-bordered table-striped table-hover table-condensed'>
                        <thead>
                            <tr>
                            <th>Days</th>
                            <>
                            {
                            Obj.Schedule?Obj.Schedule["Monday"].map(function(period,index)
                            {
                                return(
                                    <th key={index}>{index+1}</th>
                                )
                            }):""
                            }</>
                            </tr>
                            
                        </thead>
                        <tbody>
                            {
                                    Obj.Schedule?(Object.keys(Obj.Schedule).map(function(day)
                                    {
                                        return(
                                            <tr key={day}>
                                                <td>{day}</td>
                                                <>
                                                {
                                                Obj.Schedule[day].map(function(period,index)
                                                {
                                                    return(
                                                        <td key={index}>{period}</td>
                                                    )
                                                })
                                                }
                                                </>
                                            </tr>
                                        )
                                    })):""
                            }
                        </tbody>
                    </table>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <p>Copyright © 2024 Academic Organizer.</p>
  </footer>
</div>

  )
}

export default TeacherViewComponent
