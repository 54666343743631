import React from 'react'
import Header from '../Components/Header'
import TodayComponent from '../Components/TodayComponent'

const TodayAttendance = () => {
  return (
    <div className="main-wrapper">
      <Header/>
      <TodayComponent/>
    </div>
  )
}

export default TodayAttendance
