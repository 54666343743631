import React from 'react'
import Header from '../Components/Header'
import TeacherViewComponent from '../Components/TeacherViewComponent'

const TeacherView = () => {
  return (
    <div className='main-wrapper'>
      <Header/>
      <TeacherViewComponent/>
    </div>
  )
}

export default TeacherView
