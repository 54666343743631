import React from 'react'
import Header from '../Components/Header'
import AddTeacherComponent from '../Components/AddTeacherComponent'
import Sidebar from '../Components/Sidebar'

const AddTeacher = () => {
  return (
    <div className="main-wrapper">
      <Header/>
      <AddTeacherComponent/>
    </div>
  )
}

export default AddTeacher
