import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import DasboardComponent from '../Components/DasboardComponent'
import AddSchool from '../Components/AddSchool'
import Firebase from '../Firebase'

const Dashboard = () => {
  const[Condition,SetCondition]=useState(true)
  useEffect(function()
  {
    Firebase.child("SchoolTimetable/SchoolDetails").on("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetCondition(false)
      }
      else
      {
        SetCondition(true)
      }
    })
  },[])
  return (
    <div className="main-wrapper">
      <Header/>
      {
        Condition?<DasboardComponent/>:(
          <AddSchool/>
        )
      }
    </div>
  )
}

export default Dashboard