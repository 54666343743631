import React, { useEffect, useState } from 'react'
import Firebase from '../Firebase'
import { useNavigate } from 'react-router-dom'

const ClassesList = () => {
  const[Obj,SetObj]=useState({
    ClassName:"",
    Head:"",
  })
  const[Data,SetData]=useState({})
  const[Input,SetInput]=useState("")
  var navigate=useNavigate()
  function set(event)
  {
    SetObj({...Obj,[event.target.name]:event.target.value})
  }
  useEffect(function()
  {
    Firebase.child("SchoolTimetable/TeachersList").once("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetData({})
      }
      else
      {
        SetData(snap.val())
      }
    })
  },[])
  function create(e)
  {
    SetInput(parseInt(e.target.value))
    document.getElementById("row").innerHTML=""
    for(var i=0;i<parseInt(e.target.value);i++)
    {
      var box=document.createElement("div")
      box.className="col-12 col-sm-4"

      var block=document.createElement("div")
      block.className="form-group local-forms"

      var label=document.createElement("label")
      label.innerHTML=`Subject-${i+1}`
      var span=document.createElement("span")
      span.className="login-danger"
      span.innerHTML="*"
      label.append(span)
      block.append(label)


      var input=document.createElement("input")
      input.type="text"
      input.className="form-control"
      input.placeholder=`Enter Subject-${i+1}`
      input.id=`Subject-${i}`
      block.append(input)
      box.append(block)
      document.getElementById("row").append(box)
    }
  }
  function Get()
  {
    var obj={}
    var count=0
    for(var i=0;i<Input;i++)
    {
     var subject= document.getElementById(`Subject-${i}`).value
     if(subject!="")
     {
      obj[i]=subject
     }
     else
     {
      count=count+1
     }
    }
    if(count==0)
    {
      return obj
    }
  }
  function Save(e)
  {
    e.preventDefault()
   var subjects= Get()
   if(subjects!=null)
   {
    if(Object.keys(Data).length!=0)
    {
      var objs={} 
      for(var i=0;i<Object.keys(subjects).length;i++)
      {
        var arrayteach=Object.keys(Data)
        for(var j=0;j<arrayteach.length;j++)
        {
          if(Data[arrayteach[j]].Specialist==subjects[i])
          {
             objs[subjects[i]]="Found"
             break 
          }
          else
          {
            objs[subjects[i]]="Not"
          }
        }
      }
      var count=0
      Object.keys(objs).map(function(key)
      {
        if(objs[key]=="Not")
        {
          count=count+1
          alert(`${key} Teacher is not found in your Records`)
        }
      })
      if(count==0)
      {
        if(Obj.ClassName!="" && Obj.Head!="" && Input!="")
        {
         var objjects={...Obj,"NumberofSubjects":Input,"Subjects":objs}
         Firebase.child("SchoolTimetable/Classes").push(objjects,err=>{
         if(err)
         { 
           alert("Error Occured")
         }
         else
         {
          alert("Class is saved")
          navigate("/Classes")
         }
        })
    }
    else
    {
      alert("Field is Empty")
    }
      }
      else
      {
        alert("Please update your teachers list with new teachers") 
      }
     }
   }
   else
   {
    alert("Enter the respective subjects")
   }
  }
  return (
    <div>
      <div className="page-wrapper">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Add Classes</h3>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-12">
                  <h5 className="form-title"><span>Classes Details</span></h5>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Class Name: <span className="login-danger">*</span></label>
                    <input type="text" placeholder='Enter Class Name' className="form-control" onChange={set} name='ClassName' />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Head of Class<span className="login-danger">*</span></label>
                    <input type="text" placeholder='Enter Head of Class' className="form-control" name='Head' onChange={set}/>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Number of Subjects<span className="login-danger">*</span></label>
                    <input type="number" onChange={create} className="form-control" name='numberofsubjects' />
                  </div>
                </div>
                <div id="row" className='row'></div>
                <div className="col-12">
                  <div className="student-submit">
                    <button onClick={Save} className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default ClassesList
