import React from 'react'
import Header from '../Components/Header'
import HelpComponent from '../Components/HelpComponent'

const Help = () => {
  return (
    <div>
      <HelpComponent/>
    </div>
  )
}

export default Help
