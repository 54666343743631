import React from 'react'
import Header from '../Components/Header'
import MonthComponent from '../Components/MonthComponent'

const MonthAttendance = () => {
  return (
    <div className="main-wrapper">
      <Header/>
      <MonthComponent/>
    </div>
  )
}

export default MonthAttendance
