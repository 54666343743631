import React, { useEffect, useState } from 'react'
import JsPDF from 'jspdf';
import * as xlsx from "xlsx"
import "./Certificate1.css"
const Certificate1 = (props) => {
  const[Obj,SetObj]=useState([])
  useEffect(function()
  {
    SetObj(props.data)
  },[])
  function generatePDF()
 {
 var arr= document.getElementsByClassName('i')

for (let index = 0; index < arr.length; index++) {
  const report = new JsPDF('landscape','px',[700,1000]);
  report.html(arr[index]).then(() => {
     report.save('Certificate.pdf');
 });
}
 }
 function Print()
{       
  let originalContents = document.body.innerHTML;
    let printContents = document.getElementsByClassName('i');
    for(var i=0;i<printContents.length;i++)
    {
      document.body.innerHTML = printContents[i].innerHTML;
      window.print()
      document.body.innerHTML = originalContents; 
    }
}
  return (
  <div>
     <button className='btn btn-primary' onClick={Print}>Print</button>
      <button className='btn btn-primary' onClick={generatePDF}>Download Pdf's</button>
    <div className='box'>
    {
       Obj? Obj.map(function(obj,index)
        {
            const { getJsDateFromExcel } = require("excel-date-to-js");
            var date=getJsDateFromExcel(obj.Date);
            return(
             <div className='i center' key={index}>
                 <div className='main'>
                <div className="first" >
                    <span className='firstchild'>Certificate of Completion</span>
                    <br /><br />
                    <span className="second" ><i>This is to certify that</i></span>
                    <br /><br />
                    <span className="third" ><b>{obj.Name}</b></span><br /><br />
                    <span className="first1" ><i>has completed the course</i></span>
                    <br />
                    <span className="second2" >{obj.Course}</span>
                    <br /><br />
                    <span className="third3" >with score of <b>({obj.Score})%</b></span>
                    <br /><br />
                    <br />
                    <span className="one1" ><i>Date</i></span><br />
                    {`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}
                    <br /><br />
                    <span className="two2" >Signature</span>
                </div>
            </div>
            <br />
             </div>
            )
        }):""
    }
    </div>
  </div>

  )
}

export default Certificate1
