import React, { useState } from 'react'
import "./Style.css"
import Certificate1 from './Certificate1'
const CertificateTemplates = (props) => {
    const[Condition,SetCondition]=useState(false)
  return (
    <div>
        {
            Condition?<Certificate1 data={props.data}/>:<div className="row">
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="card bg-comman w-100">
                <div className="card-body">
                  <div onClick={()=>SetCondition(true)} className="db-widgets d-flex justify-content-between align-items-center">
                 <img className="images" src="assets/img/Certificate1.PNG" alt="Dashboard Icon" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="card bg-comman w-100">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                  <img className="images" src="assets/img/Certificate2.png" alt="Dashboard Icon" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="card bg-comman w-100">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                   
                  <img className="images" src="assets/img/Certificate3.PNG" alt="Dashboard Icon" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="card bg-comman w-100">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    
                  <img className="images" src="assets/img/Certificate4.PNG" alt="Dashboard Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
    </div>
  )
}

export default CertificateTemplates
