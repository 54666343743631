import React, { useEffect, useRef, useState } from 'react'
import Firebase from '../Firebase'
import "./Style.css"
import JsPDF from 'jspdf'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import { Link, useNavigate } from 'react-router-dom'
const Timetablee = (props) => {
    const [Obj, SetObj] = useState(props.obj)
    const[timetable,settimetable]=useState(props.timetable)
    const[Lunch,SetLunch]=useState(props.lunch)
    const [Periods, SetPeriods] = useState(props.Periods)
    const [Days, SetDays] = useState(props.Days)
    const[Subject,SetSubject]=useState(props.subjects)
    const[OldIndex,SetOldIndex]=useState("")
    const[oldday,Setoldday]=useState("")
    const[Btns,SetBtns]=useState([])
    const[Btns1,SetBtns1]=useState([])
    const[TeachersList,SetTeachersList]=useState({})
    const[Class,SetClass]=useState(Obj.Classes[props.class].ClassName)
    var navigate=useNavigate()
    var overlay=useRef()
    var modal=useRef()
    var teachers=useRef()
    var subjects=useRef()
    var table=useRef()
    var checkbtn=useRef()
    useEffect(function()
    {
        Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetTeachersList({})
            }
            else
            {
                SetTeachersList(snap.val())
            }
        })
    },[])
    function Check()
    {
        var count=0
        Object.keys(timetable).map(function(key)
        {
            timetable[key].map(function(period,index)
            {
                if(period.Teacher!=null)
                {
                    if(index<Lunch)
                    {
                        Object.keys(Obj.TeachersList).map(function(teach)
                    {
                        if(Obj.TeachersList[teach].Name==period.Teacher)
                        {
                            if(Obj.TeachersList[teach].Schedule[key][index]=="free")
                            {
                                document.getElementById(`${key}${index}`).style.backgroundColor="white"
                                document.getElementById(`${key}${index}`).style.color="black"
                            }
                            else
                            {
                                count=count+1
                                document.getElementById(`${key}${index}`).style.backgroundColor="red"
                                document.getElementById(`${key}${index}`).style.color="white"
                            }
                        }
                    })
                    }
                    else
                    {
                        Object.keys(Obj.TeachersList).map(function(teach)
                    {
                        if(Obj.TeachersList[teach].Name==period.Teacher)
                        {
                            if(Obj.TeachersList[teach].Schedule[key][index-1]=="free")
                            {
                                document.getElementById(`${key}${index}`).style.backgroundColor="white"
                                document.getElementById(`${key}${index}`).style.color="black"
                            }
                            else
                            {
                                count=count+1
                                document.getElementById(`${key}${index}`).style.backgroundColor="red"
                                document.getElementById(`${key}${index}`).style.color="white"
                            }
                        }
                    })
                    }
                }
            })
        })
        if(count==0)
        {
            Save()
        }
        else
        {
            alert("Make your timetable error free")
        }
    }
    function Save()
    {
        if(Object.keys(TeachersList).length==0)
        {
            alert("Teacher List cannot updated")
        }
        else
        {
            Object.keys(timetable).map(function(dayss)
            {
                timetable[dayss].map(function(periods,index)
                {
                    if(periods.Subject=="Lunch")
                    {
                        
                    }
                    else
                    {
                        if(index<Lunch)
                        {
                            Object.keys(TeachersList).map(function(teacherkey)
                            {
                                if(periods.Teacher==TeachersList[teacherkey].Name)
                                {
                                    if(TeachersList[teacherkey].Schedule[dayss][index]=="free")
                                    {
                                        TeachersList[teacherkey].Schedule[dayss][index]=Class
                                    }
                                }
                            })
                        }
                        else
                        {
                            Object.keys(TeachersList).map(function(teacherkey)
                            {
                                if(periods.Teacher==TeachersList[teacherkey].Name)
                                {
                                    if(TeachersList[teacherkey].Schedule[dayss][index-1]=="free")
                                    {
                                        TeachersList[teacherkey].Schedule[dayss][index-1]=Class
                                    }
                                }
                            })
                        }
                    }
                })
                
            })
        }
         Firebase.child("SchoolTimetable/TeachersList").update(TeachersList,err=>{
            if(err)
            {
                alert("Error Occured in updating teacher Schedule")
            }
         })
         Firebase.child(`SchoolTimetable/Classes/${props.class}/Timetable`).set(timetable,err=>{
            if(err)
            {
                alert("Error Occured in updating timetable")
            }
            else
            {
                alert("Timetable saved")
                navigate("/Classes")
            }
         })
    }
    function Show(day,index)
    {
        Setoldday(day)
        SetOldIndex(index)
        checkbtn.current.style.opacity=0.5
        // overlay.current.style.display="block"
        modal.current.style.display="block" 
        table.current.style.opacity=0.5
        teachers.current.value=timetable[day][index].Teacher
        subjects.current.value=timetable[day][index].Subject
    }
    function Close()
    {
        // overlay.current.style.display="none"
        checkbtn.current.style.opacity=1
        modal.current.style.display="none"
        table.current.style.opacity=1
        Setoldday("")
        SetOldIndex("")
        SetBtns([])
        SetBtns1([])
    }
    function ChangeSubject(e)
    {
        var PrimaryButtons=[]
        Object.keys(Obj.TeachersList).map(function(key)
        {
            if(Obj.TeachersList[key].Specialist==e.target.value)
            {
                PrimaryButtons.push(Obj.TeachersList[key].Name)
            }
        })
        SetBtns([...PrimaryButtons])
        var additional=[]
        Object.keys(Obj.TeachersList).map(function(key)
        {
           var others =Obj.TeachersList[key].OtherSubject.split(",")
           others.map(function(subss)
           {
            if(subss==e.target.value)
            {
                additional.push(Obj.TeachersList[key].Name)
            }
           })
        })
        SetBtns1([...additional])
    }
    function set(name)
    {
        teachers.current.value=name
    }
    function Change()
    {
        timetable[oldday][OldIndex].Subject=subjects.current.value
        timetable[oldday][OldIndex].Teacher=teachers.current.value
        settimetable({...timetable})
        Close()
    }
    function generatePDF()
    {
     const report = new JsPDF('landscape','px',[700,2100]);
     report.html(table.current).then(() => {
        report.save('Timetable.pdf');
   })
    }
  return (
    <div>
    <div ref={overlay} className="overlay"></div>
   <div ref={modal} id='modal' className="col-md-3 col-sm-6 col-xs-10">
  <h1 className='text-primary'>Change Period</h1>
  <span className="cross" onClick={Close}>×</span>
  <div className="col-md-12 col-sm-12">
    <label>Subjects:</label>
    <select className='form-control' onChange={ChangeSubject} ref={subjects}>
        {
            Subject.Subjects?Object.keys(Subject.Subjects).map(function(sub)
            {
                return(
                    <option value={sub} key={sub}>{sub}</option>
                )
            }):""
        }
    </select>
    <div>
        <label>Primary Teacher:</label><br />
        {
            Btns?Btns.map(function(name)
            {
                return(
                    <button className='btn btns btn-primary' key={name} onClick={()=>set(name)}>{name}</button>
                )
            }):""
        }
        <br />
        <label>Additional Teacher:</label>
        <br />
        {
             Btns1?Btns1.map(function(name)
             {
                 return(
                     <button className='btn btns btn-warning' key={name} onClick={()=>set(name)}>{name}</button>
                 )
             }):""
        }
    </div>
    <label>Teachers:</label>
    <select className='form-control' ref={teachers}>
        {
            Obj.TeachersList?Object.keys(Obj.TeachersList).map(function(key)
            {
                return(
                    <option key={key} value={Obj.TeachersList[key].Name}>{Obj.TeachersList[key].Name}</option>
                )
            }):""
        }
    </select>
  </div>
  <div className="pop col-md-12 col-sm-12">
    <br />
    <button className="btn btn-primary" onClick={Change}>Change</button>
  </div>
</div>
<div className="row">
                    <div className="col-sm-12">
                        <div className="card card-table">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Time Table</h3>
                                        </div>
                                        <div className="col-auto text-end float-end ms-auto download-grp">
                                        <ReactHTMLTableToExcel
                                                        table="table"
                                                        filename="Timetable"
                                                        sheet="Timetable"
                                                        className="btn btn-outline-primary me-2"
                                                        buttonText="Download Excel">
                                                    </ReactHTMLTableToExcel>
                                            <a onClick={generatePDF} className="btn btn-outline-primary me-2"><i className="fas fa-download" /> Download</a>
                                            <Link to="/AddClasses" className="btn btn-primary"><i className="fas fa-plus" /></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='container scroll' >
                            <table id='table' ref={table} className='table border-0 table-hover table-striped table-condensed'>
                                <thead>
                                    <tr className='text-center'> 
                                        <th>Days</th>
                                        <>
                                        {
                                            Periods ? Periods.map(function (obj, index) {
                                                return (
                                                    <th key={index}>{obj.period} ({obj.starttime} to {obj.endtime})</th>
                                                )
                                            }) : ""
                                        }</>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        timetable?Object.keys(timetable).map(function(key)
                                        {
                                            return(
                                                <tr className='text-center' key={key}>
                                                    <td>{key}</td>
                                                    {
                                                        timetable[key].map(function(period,index)
                                                        {   
                                                            if(period.Subject=="Lunch")
                                                            {
                                                                return(
                                                                    <td key={index}>{period.Subject}</td>
                                                                )
                                                            }
                                                            else
                                                            {
                                                                if(index<Lunch)
                                                                {
                                                                    return(
                                                                        <td id={`${key}${index}`} key={index} onClick={()=>Show(key,index)}>{period.Subject}/By:-{period.Teacher}</td>
                                                                    )
                                                                }
                                                                else
                                                                {
                                                                    return(
                                                                        <td id={`${key}${index}`} key={index} onClick={()=>Show(key,index)}>{period.Subject}/By:-{period.Teacher}</td>
                                                                    )
                                                                }
                                                               
                                                            }
                                                        })
                                                    }
                                                </tr>
                                            )
                                        }):""
                                    }
                                </tbody>
                            </table>
                            <div className="text-center">
                        <button className="btn btn-primary" ref={checkbtn} onClick={Check}>Check & Save</button>
                    </div>
         </div>                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
       
    
  )
}

export default Timetablee
