import React from 'react'
import {BrowserRouter,Routes,Route} from "react-router-dom"
import Dashboard from './Pages/Dashboard'
import AddTeacher from './Pages/AddTeacher'
import TeacherList from './Pages/TeacherList'
import EditTeacher from './Pages/EditTeacher'
import TodayAttendance from './Pages/TodayAttendance'
import MonthAttendance from './Pages/MonthAttendance'
import Error from './Pages/Error'
import Help from './Pages/Help'
import Student_dashboard from './Pages/Student_dashboard'
import Teacher_dashboard from './Pages/Teacher_dashboard'
import Login from './Pages/Login'
import Signup from './Pages/Signup'
import AddClasses from './Pages/AddClasses'
import Classes from './Pages/Classes'
import TeacherView from './Pages/TeacherView'
import CreateTimetable from './Components/CreateTimetable'
import Timetable from './Pages/Timetable'
import ShowTimetable from './Pages/ShowTimetable'
import Certificate from './Pages/Certificate'
const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/Dashboard" element={<Dashboard/>}></Route>
        <Route path="/AddTeacher" element={<AddTeacher/>}></Route>
        <Route path="/TeacherList" element={<TeacherList/>}></Route>
        <Route path="/TeacherView/:key" element={<TeacherView/>}></Route>
        <Route path='/EditTeacher/:key' element={<EditTeacher/>}></Route>
        <Route path="/TodayAttendance" element={<TodayAttendance/>}></Route>
        <Route path="/MonthAttendance" element={<MonthAttendance/>}></Route>
        <Route path="/Error" element={<Error/>}></Route>
        <Route path="/CreateTimetable/:Class" element={<Timetable/>}></Route>
        <Route path="/Help" element={<Help/>}></Route>
        <Route path="/StudentDashboard" element={<Student_dashboard/>}></Route> 
        <Route path="/TeacherDashboard/:key" element={<Teacher_dashboard/>}></Route>
        <Route path="/" element={<Login/>}></Route>
        <Route path="/Signup" element={<Signup/>}></Route>
        <Route path="/AddClasses" element={<AddClasses/>}></Route>
        <Route path="/Classes" element={<Classes/>}></Route>
        <Route path="/ShowTimetable/:key" element={<ShowTimetable/>}></Route>
        <Route path='/Certificate' element={<Certificate/>}></Route>
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
