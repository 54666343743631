import React from 'react'
import Header from '../Components/Header'
import Teacher from '../Components/Teacher'
import Sidebar from '../Components/Sidebar'

const TeacherList = () => {
  return (
    <div className='main-wrapper'>
      <Header/>
      <Teacher/>
    </div>
  )
}

export default TeacherList
