import React from 'react'
import Header from '../Components/Header'
import ClassesList from '../Components/ClassesList'

const AddClasses = () => {
  return (
 <div className='main-wrapper'>
    <Header/>
    <ClassesList/>
 </div>
  )
}

export default AddClasses
