import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Firebase from '../Firebase'

const EditTeacherComponent = () => {
  const[Obj,SetObj]=useState({})
  const[Data,SetData]=useState({})
  var navigate=useNavigate()
  const[PassError,SetPassError]=useState(true)
  const[EmailError,SetEmailError]=useState(true)
  const[PhoneError,SetPhoneError]=useState(true)
  const[UsernameError,SetUsernameError]=useState(true)
  const[ZipCodeError,SetZipCodeError]=useState(true)
  var pass=useRef()
  var phone=useRef()
  var email=useRef()
  var username=useRef()
  var zip=useRef()
  var Name=useRef()
  var Phone=useRef()
  var Email=useRef()
  var Gender=useRef()
  var DOB=useRef()
  var Qualification=useRef()
  var Experience=useRef()
  var MainSubject=useRef()
  var OtherSubject=useRef()
  var Classes=useRef()
  var Username=useRef()
  var Password=useRef()
  var Password1=useRef()
  var Address=useRef()
  var City=useRef()
  var State=useRef()
  var ZipCode=useRef()
  var Country=useRef()
  var {key}=useParams()
  useEffect(function()
  {
    Firebase.child(`SchoolTimetable/TeachersList/${key}`).on("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetObj({})
        navigate("/TeacherList")
      }
      else
      {
        SetObj(snap.val())
        Name.current.value=snap.val().Name?snap.val().Name:""
        Phone.current.value=snap.val().Phone?snap.val().Phone:""
        Email.current.value=snap.val().Email?snap.val().Email:""
        Gender.current.value=snap.val().Gender?snap.val().Gender:"None"
        Experience.current.value=snap.val().Experience?snap.val().Experience:""
        MainSubject.current.value=snap.val().Specialist?snap.val().Specialist:""
        OtherSubject.current.value=snap.val().OtherSubject?snap.val().OtherSubject:""
        Classes.current.value=snap.val().Range?snap.val().Range:""
        DOB.current.value=snap.val().DOB?snap.val().DOB:""
        Password.current.value=snap.val().Password?snap.val().Password:""
        Username.current.value=snap.val().Username?snap.val().Username:""
        Qualification.current.value=snap.val().Qualification?snap.val().Qualification:""
        Address.current.value=snap.val().Address?snap.val().Address:""
        City.current.value=snap.val().City?snap.val().City:""
        ZipCode.current.value=snap.val().ZipCode?snap.val().ZipCode:""
        State.current.value=snap.val().State?snap.val().State:""
        Country.current.value=snap.val().Country?snap.val().Country:""
      }
    })
    Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
        {
          if(snap.val()==null)
          {
            SetData({})
          }
          else
          {
            SetData(snap.val())
          }
        })
  },[])
  function set(event)
  {
    SetObj({...Obj,[event.target.name]:event.target.value.trim()})
  }
  function Save(e)
  {
    e.preventDefault()
    if(Obj.Name!="" && Obj.Phone!="" && Obj.Email!="" && Obj.Specialist!="" && Obj.OtherSubject!="" && Obj.Gender!="None" && Obj.DOB!="" && Obj.Qualification!="" && Obj.Experience!="" && Obj.Range!="" && Obj.Username!="" && Obj.Password!="" && Obj.Address!="" && Obj.City!="" && Obj.State!="" && Obj.ZipCode!="" && Obj.Country!="")
    {
      if(PassError==true)
      {
        if(PhoneError==true && EmailError==true && UsernameError==true && ZipCodeError==true)
        {
          Firebase.child(`SchoolTimetable/TeachersList/${key}`).update(Obj,err=>{
            if(err)
            {
              alert("Error Occured")
            }
            else
            {
              alert("Teacher updated")
              navigate("/TeacherList")
            }
          }) 
        }
        else
        {
          alert("Error in getting Mobile Number,Email and Username and zipcode")
        }
      }
      else
      {
        alert("Your password does not matched")
      }
    }
    else
    {
      alert("Field is Empty")
    }
  }
  function Check(event)
  {
    if(event.target.value=="")
    {
      pass.current.innerHTML="Enter the Repeat Password"
      pass.current.style.color="red"
      SetPassError(false)
    }
    else
    {
      if(Obj.Password!="")
      {
        if(Obj.Password==event.target.value.trim())
        {
          pass.current.innerHTML="Strong Password"
          pass.current.style.color="green"
          SetPassError(true)
        }
        else
        {
          pass.current.innerHTML="Your Password does not match. Enter the correct one"
          pass.current.style.color="red"
          SetPassError(false)
        }
      } 
      else
      {
        pass.current.innerHTML="Weak Password"
        pass.current.style.color="orange"
        SetPassError(false)
      }
    }
  }
  function phncheck(e)
  {
    if(e.target.value=="")
    {
      phone.current.innerHTML="Enter Mobile Number"
      phone.current.style.color="orange"
      SetObj({...Obj,"Phone":""})
      SetPhoneError(false)
    }
    else
    {
      Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
      {
        if(snap.val()==null)
        {
          if(e.target.value.length==10)
              {
                SetObj({...Obj,"Phone":e.target.value.trim()})
                SetPhoneError(true)
                phone.current.innerHTML="Mobile Number is perfect"
                phone.current.style.color="green"
              }
              else
              {
                SetObj({...Obj,"Phone":""})
                SetPhoneError(false)
                phone.current.innerHTML="Enter a valid Mobile Number"
                phone.current.style.color="red"
              }
        }
        else
        {
         var result= Object.keys(snap.val()).reverse().filter(function(key)
          {
            return snap.val()[key].Phone==e.target.value.trim()
          })
          if(result=="")
          {
            if(e.target.value.length==10)
            {
              SetObj({...Obj,"Phone":e.target.value.trim()})
              SetPhoneError(true)
              phone.current.innerHTML="Mobile Number is perfect"
              phone.current.style.color="green"
            }
            else
            {
              SetObj({...Obj,"Phone":""})
              SetPhoneError(false)
              phone.current.innerHTML="Enter a valid Mobile Number"
              phone.current.style.color="red"
            }
          }
          else
          {
            SetObj({...Obj,"Phone":""})
            SetPhoneError(false)
            phone.current.innerHTML="This Mobile No. is in our records.Try with different Mobile No."
            phone.current.style.color="red"
          }
        }
      })
    }
  }
  function emailcheck(e)
  {
    if(e.target.value=="")
    {
      email.current.innerHTML="Enter Email"
      email.current.style.color="orange"
      SetObj({...Obj,"Email":""})
      SetEmailError(false)
    }
    else
    {
      Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
      {
        if(snap.val()==null)
        {
          SetObj({...Obj,"Email":e.target.value.toLowerCase()})
          SetEmailError(true)
          email.current.innerHTML="Email is perfect"
          email.current.style.color="green"
        }
        else
        {
         var result= Object.keys(snap.val()).reverse().filter(function(key)
          {
            return snap.val()[key].Email==e.target.value.toLowerCase()
          })
          if(result=="")
          {
            SetObj({...Obj,"Email":e.target.value.toLowerCase()})
            SetEmailError(true)
            email.current.innerHTML="Email is perfect"
            email.current.style.color="green"
          }
          else
          {
            SetObj({...Obj,"Email":""})
            SetEmailError(false)
            email.current.innerHTML="This Email is in our records.Try with different Email."
            email.current.style.color="red"
          }
        }
      })
    }
  }
  function usernamecheck(e)
  {
    if(e.target.value=="")
    {
      username.current.innerHTML="Enter Username"
      username.current.style.color="orange"
      SetObj({...Obj,"Username":""})
      SetUsernameError(false)
    }
    else
    {
      Firebase.child("SchoolTimetable/TeachersList").on("value",function(snap)
      {
        if(snap.val()==null)
        {
          SetObj({...Obj,"Username":e.target.value.trim()})
          SetUsernameError(true)
          username.current.innerHTML="Username is perfect"
          username.current.style.color="green"
        }
        else
        {
         var result= Object.keys(snap.val()).reverse().filter(function(key)
          {
            return snap.val()[key].Username==e.target.value.trim()
          })
          if(result=="")
          {
            SetObj({...Obj,"Username":e.target.value.trim()})
            SetUsernameError(true)
            username.current.innerHTML="Username is perfect"
            username.current.style.color="green"
          }
          else
          {
            SetObj({...Obj,"Username":""})
            SetUsernameError(false)
            username.current.innerHTML="This Username is in our records.Try with different Username."
            username.current.style.color="red"
          }
        }
      })
    }
  }
  function Zip(e)
  {
    if(e.target.value=="")
    {
      SetZipCodeError(false)
      SetObj({...Obj,"ZipCode":""})
      zip.current.innerHTML="Enter Zip Code"
      zip.current.style.color="orange"
    }
    else
    {
      if(e.target.value.length==6)
      {
        SetZipCodeError(true)
        SetObj({...Obj,"ZipCode":e.target.value.trim()})
        zip.current.innerHTML="Zip Code is perfect"
        zip.current.style.color="green"
      }
      else
      {
        SetZipCodeError(false)
        SetObj({...Obj,"ZipCode":""})
        zip.current.innerHTML="Enter a valid Zip Code"
        zip.current.style.color="red"
      }
    }
  }
  return (
    <div className="page-wrapper">
  <div className="content container-fluid">
    <div className="page-header">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Edit Teachers</h3>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-12">
                  <h5 className="form-title"><span>Basic Details</span></h5>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Name <span className="login-danger">*</span></label>
                    <input type="text" ref={Name} onChange={set} name='Name' className="form-control" placeholder="Enter Name" />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Mobile Number<span className="login-danger">*</span></label>
                    <input type="tel" maxLength={10} minLength={10} onChange={phncheck} ref={Phone} name='Phone' className="form-control" required placeholder="Enter Mobile Number" />
                    <p ref={phone}></p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Gender <span className="login-danger">*</span></label>
                    <select onChange={set} ref={Gender} name='Gender' className="form-control select">
                      <option value="None">None</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms calendar-icon">
                    <label>Date Of Birth <span className="login-danger">*</span></label>
                    <input className="form-control datetimepicker" onChange={set} ref={DOB} name='DOB' type="date" placeholder="DD-MM-YYYY" />
                  </div>
                </div>
                <div className="col-12 col-sm-4 local-forms">
                  <div className="form-group">
                    <label>Qualification <span className="login-danger">*</span></label>
                    <input list='list1' className="form-control" onChange={set} ref={Qualification} name='Qualification' type="text" placeholder="Enter Qualification" />
                    <datalist id='list1'>
                      {
                        Data?(Object.keys(Data).reverse().map(function(key,index)
                        {
                          if(index<5)
                          {
                            return(
                              <option key={key} value={Data[key].Qualification}>{Data[key].Qualification}</option>
                            )
                          }
                        })):""
                      }
                    </datalist>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Experience <span className="login-danger">*</span></label>
                    <input list='list2' className="form-control" onChange={set} ref={Experience} name='Experience' type="number" placeholder="Enter Experience" />
                    <datalist id='list2'>
                      {
                        Data?(Object.keys(Data).reverse().map(function(key,index)
                        {
                          if(index<5)
                          {
                            return(
                              <option key={key} value={Data[key].Experience}>{Data[key].Experience}</option>
                            )
                          }
                        })):""
                      }
                    </datalist>
                  </div>
                </div>
                 <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label> Main Subjects <span className="login-danger">*</span></label>
                    <input list='list3' className="form-control" onChange={set} ref={MainSubject} name='Specialist' type="text" placeholder="Enter Main Subject" />
                    <datalist id='list3'>
                      {
                        Data?(Object.keys(Data).reverse().map(function(key,index)
                        {
                          if(index<5)
                          {
                            return(
                              <option key={key} value={Data[key].Specialist}>{Data[key].Specialist}</option>
                            )
                          }
                        })):""
                      }
                    </datalist>
                  </div>
                </div> 
                 <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Other Subjects <span className="login-danger">*</span></label>
                    <input list='list4' className="form-control" onChange={set} ref={OtherSubject} name='OtherSubject' type="text" placeholder="Enter Other Subjects separated with commas" />
                    <datalist id='list4'>
                      {
                        Data?(Object.keys(Data).reverse().map(function(key,index)
                        {
                          if(index<5)
                          {
                            return(
                              <option key={key} value={Data[key].OtherSubject}>{Data[key].OtherSubject}</option>
                            )
                          }
                        })):""
                      }
                    </datalist>
                  </div>
                </div>
                  <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Classes<span className="login-danger">*</span></label>
                    <input className="form-control" onChange={set} ref={Classes} name='Range' type="text" placeholder="Enter Classes" />
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="form-title"><span>Login Details</span></h5>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Username <span className="login-danger">*</span></label>
                    <input type="text" onChange={usernamecheck} ref={Username} name='Username' className="form-control" placeholder="Enter Username" required/>
                    <p ref={username}></p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Email ID <span className="login-danger">*</span></label>
                    <input type="email" onChange={emailcheck} ref={Email} name='Email' className="form-control" placeholder="Enter Mail Id" required/>
                    <p ref={email}></p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Password <span className="login-danger">*</span></label>
                    <input type="password" onChange={set} ref={Password} name='Password' className="form-control" placeholder="Enter Password" />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Repeat Password <span className="login-danger">*</span></label>
                    <input type="password" onChange={Check} ref={Password1} name='Password1' className="form-control" placeholder="Repeat Password" />
                    <p className='passwordmessage' ref={pass}></p>
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="form-title"><span>Address</span></h5>
                </div>
                <div className="col-12">
                  <div className="form-group local-forms">
                    <label>Address <span className="login-danger">*</span></label>
                    <input type="text" onChange={set} ref={Address} name='Address' className="form-control" placeholder="Enter address" />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>City <span className="login-danger">*</span></label>
                    <input type="text" onChange={set} ref={City} name='City' className="form-control" placeholder="Enter City" />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>State <span className="login-danger">*</span></label>
                    <input type="text" required onChange={set} ref={State} name='State' className="form-control" placeholder="Enter State" />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Zip Code <span className="login-danger">*</span></label>
                    <input type="tel" maxLength={6} minLength={6} onChange={Zip} ref={ZipCode} name='ZipCode' className="form-control" placeholder="Enter Zip" />
                  <p ref={zip}></p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Country <span className="login-danger">*</span></label>
                    <input type="text" onChange={set} ref={Country} name='Country' className="form-control" placeholder="Enter Country" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="student-submit">
                    <button onClick={Save} className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default EditTeacherComponent
